import { useEffect, useState } from "react"
import styles from "./CardsTickers65.module.css"
import { filterData, SearchType } from 'filter-data';

// MÓDULO DE GESTÃO DE RISCO
const CardTickers65 = (props) => {

    const [baseAtiva, setBaseAtiva] = useState(props.baseAtiva)
    const [baseImpressao, setBaseImpressao] = useState([])

    let RSLocale = Intl.NumberFormat('pt-BR');

    // SEMPRE QUE A BASE REAL TIME MUDAR
    useEffect(()=>{

        var baseImpressaoTemp = []
        var baseFinalImpressaoTemp = []
        if(baseAtiva.length>0){

            // PRIMEIRO VERIFICA AS OPÇÕES QUE PODEM SER DESMONTADAS
            for(let opcao=0; opcao < baseAtiva.length; opcao++){
                var ticker = baseAtiva[opcao]["Ativo"]
                var precoMedio = baseAtiva[opcao]["Preco Medio"]
                var precoAtual = baseAtiva[opcao]["ultimo"]

                // TRAZ O PREÇO ATUAL DA OPÇÃO CASO ELE ESTEJA PRESENTE NA BASE AO VIVO
                if(props.baseRealTime === undefined){}else{
                    if(props.baseRealTime[ticker] === undefined){}else{
                        precoAtual = props.baseRealTime[ticker]["Último"]
                    }
                }
  
                var retorno = 1 - precoAtual/precoMedio

                // CONSIDERA SOMENTE PUT'S COM RETORNO JÁ ACIMA DE 65%
                if(baseAtiva[opcao]["tipo"] ==="PUT" || baseAtiva[opcao]["tipo"] ==="CALL" ){
                    if(baseAtiva[opcao]["Estrategia de Opcoes"] != "Trava"){
                        if(retorno > 0.6){
                            baseImpressaoTemp.push(baseAtiva[opcao])
                        }
                    }
                }

            }

            if(baseImpressaoTemp.length>0){
                // MONTA ARRAY ÚNICO DE OPÇÕES ACIMA DE 65%
                const arrayTickers = [...new Set(baseImpressaoTemp.map((valor) => valor.Ativo))]

                // LOOP NOS TICKERS DE OPÇÕES ACIMA DE 65%
                for(let ticker=0; ticker < arrayTickers.length; ticker++){

                    // INPUT PARA BASE FINAL DE IMPRESSÃO
                    var input = {
                        ticker: arrayTickers[ticker],
                        tipo:"",
                        retorno:0,
                        precoMedio:0,
                        precoAtual:0,
                        nClientes:0
                    }

                    // FILTRA A BASE PARA AQUELA OPÇÃO ESPECÍFICA
                    var searchConditions = [
                        {
                        key: 'Ativo',
                        value: arrayTickers[ticker],
                        type: SearchType.EQ,
                        },
                    ];
                    var baseFiltradaTicker = filterData(baseImpressaoTemp, searchConditions);

                    // CAPTURA O NÚMERO DE CLIENTES DIFERENTES
                    const arrayClientes = [...new Set(baseFiltradaTicker.map((valor) => valor.Chave))]
                    var nClientes = arrayClientes.length

                    // CAPTURA O PREÇO ATUAL DO ATIVO
                    var precoAtual = baseFiltradaTicker[0]["ultimo"]

                    // TRAZ O PREÇO ATUAL DA OPÇÃO CASO ELE ESTEJA PRESENTE NA BASE AO VIVO
                    if(props.baseRealTime === undefined){}else{
                        if(props.baseRealTime[arrayTickers[ticker]] === undefined){}else{
                            precoAtual = props.baseRealTime[arrayTickers[ticker]]["Último"]
                        }
                    }

                    // LOOP NESSA BASE ESPECÍFICA PARA CÁLCULO DO PREÇO MÉDIO
                    var qtdTotal = 0
                    var spend = 0
                    for(let operacao=0; operacao< baseFiltradaTicker.length; operacao++){
                        qtdTotal = qtdTotal + baseFiltradaTicker[operacao]["Qtd Executada"]
                        spend = spend + baseFiltradaTicker[operacao]["Qtd Executada"]*baseFiltradaTicker[operacao]["Preco Medio"]
                    }

                    // CALCULA O PREÇO MÉDIO
                    var precoMedio = spend / qtdTotal

                    // CALCULA O RETORNO
                    var retorno = 1 - precoAtual/precoMedio

                    // CAPTURA O TIPO
                    var tipo = ""
                    if(baseFiltradaTicker[0]["tipo"]==="PUT"){
                        tipo = "P"
                    }else{
                        tipo = "C"
                    }

                    // POPULA O INPUT
                    input = {
                        ticker: arrayTickers[ticker],
                        tipo:tipo,
                        retorno:retorno,
                        precoMedio:precoMedio,
                        precoAtual:precoAtual,
                        nClientes:nClientes
                    }

                    // INSERE NO ARRAY TEMPORÁRIO DEVIDO
                    baseFinalImpressaoTemp.push(input)
                }

                // ATUALIZA A VARIÁVEL DE ESTADO
                setBaseImpressao(baseFinalImpressaoTemp)
            }

        }

    },[props.baseRealTime])

    return(
        <div className={`${styles.cardsRepository}`}>
            {baseImpressao.length>0 ? (baseImpressao.map((ativo) => {
                return(
                    <div key={"opPDesmontat" + ativo.ticker} className={`${styles.cardBody}`}>
                        <div className={`${styles.cardInfos}`}>
                            <div className={`${styles.ticker}`}>{ativo.ticker}</div>
                            <div className={`${styles.preco}`}>Preço: {ativo.precoMedio.toFixed(1)} / {ativo.precoAtual.toFixed(1)}</div>
                            <div className={`${styles.nclientes}`}><b>{ativo.nClientes}</b> clientes</div>
                        </div>
                        <div className={`${styles.cardEtiqueta}`}>
                            <div className={`${styles.retorno}`}>
                                {(ativo.retorno*100).toFixed(0)}%
                            </div>
                            <div className={`${styles.tipo}`}>{ativo.tipo}</div>
                        </div>
                    </div>
                )
            })):(<div className={`${styles.mensagem}`}>Nenhuma opção sugerida para desmontar</div>)}
        </div>
    )
}

export default CardTickers65