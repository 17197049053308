import CanvasJSReact from "../../canvasjs/canvasjs.react";
import { useState, useEffect, useRef} from "react";
    
var CanvasJS = CanvasJSReact.CanvasJS;

const GraficoLimpo = (props) => {

    // VARIÁVEIS DE REFERÊNCIA PARA OS CANVAS
    const graficoRef = useRef()
    const chartRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    // MONTAGEM E DESTRUIÇÃO DO GRÁFICO NA RENDERIZAÇÃO INICIAL
    useEffect(() => {
        if (graficoRef.current) {
            chartRef.current = new CanvasJS.Chart(graficoRef.current.id, props.options);
            chartRef.current.render();
        }
    
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, []);

    // ATUALIZA O GRÁFICO SEMPRE QUE AS PROPS MUDAREM SE O GRÁFICO ESTIVER VISIÍVEL
    useEffect(() => {
        if (chartRef.current && isVisible) {
            chartRef.current.options = props.options;
            chartRef.current.render();
        }
    }, [props.options, isVisible]);

    // OBSERVA SE O GRÁFICO ESTÁ VISÍVEL OU NÃO PARA QUE SÓ RENDERIZE NO MOMENTO ADEQUADO
    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsVisible(entry.isIntersecting);  // Atualiza estado de visibilidade
        });
    
        if (graficoRef.current) {
            observer.observe(graficoRef.current);
        }
    
        return () => {
            if (graficoRef.current) {
                observer.unobserve(graficoRef.current);
            }
        };
    }, []);

    return(
        <div id={props.id} ref={graficoRef}></div>
    )
}

export default GraficoLimpo