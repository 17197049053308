import { filterData, SearchType } from 'filter-data';
import { useState, useEffect, useRef } from 'react';
import styles from './MapaOpPorCliente.module.css';
import { Accordion } from 'react-bootstrap';
import TabelaAcao from './tabelaAcao';
import {convertDataToText, convertTextoToData} from '../../paginas/Admin/convertData.js'
import { app } from '../../config/firebase';
import { getFirestore, doc, onSnapshot, setDoc } from 'firebase/firestore';
import CampoForm from '../CampoForm/index.js';
import PreLoader from '../PreLoader/index.js';

// MÓDULO DE GESTÃO DE RISCO
const MapaOpPorCliente = ({baseOpcoesAtivas, baseAcoesCallCoberta, baseRealTime}) => {

    let RSLocale = Intl.NumberFormat('pt-BR');

    // VARIÁVEL FIRESTORE
    const db = getFirestore(app);

    // VARIÁVEIS DE ESTADO
    const [baseComentarios, setBaseComentarios] = useState(undefined)
    const [arrayAcoes, setArrayAcoes] = useState([])
    const [loading, setLoading] = useState(true); // VARIÁVEL DE ESTADO DE LOADING
    const [arrayClientesUnicos, setArrayClientes] = useState([])
    const [arrayVencimentos, setArrayVencimentos] = useState([])
    const [jsonCompletoImpressao, setJsonCompletoImpressao] = useState({})
    const [dataVencimento, setDataVencimento] = useState(null)
    const [baseOpcoesAtivasFiltrada, setBaseOpcoesAtivasFiltrada] = useState([])
    const [comentarioFirebase, setComentarioFirebase] = useState(undefined)
    const [comentario, setComentario] = useState(undefined) // COMENTÁRIO 

    // TODA VEZ QUE A PÁGINA CARREGAR
    useEffect(()=>{

        // ADICIONA UM LISTENER PARA CADA ADIÇÃO NA BASE  DE COMENTÁRIOS DAS AÇÕES
        const onChangeComment = onSnapshot(doc(db, "opcoesMap", "Comentarios"), (doc) => {
            setBaseComentarios(doc.data()) 
        })

        // ADICIONA UM LISTENER PARA ALTERAÇÕES NOS COMENTÁRIOS GERAIS
        const onChangeCommentGeral = onSnapshot(doc(db, "opcoesMap", "ComentarioGeral"), (doc) => {
            setComentarioFirebase(doc.data()) 
        })

        // MONTA ARRAY ÚNICO DE DATAS DE VENCIMENTO
        const arrayVencimentosTemp = [...new Set(baseOpcoesAtivas.map((valor) => valor.vencimento))]
        arrayVencimentosTemp.sort((a, b) => a - b);
        
        // EXCLUI DATAS QUE NÃO SÃO DATAS
        var arrayVencimentosTemp2 = []
        for(let i=0; i<arrayVencimentosTemp.length;i++){
            if(arrayVencimentosTemp[i] instanceof Date){
                arrayVencimentosTemp2.push(arrayVencimentosTemp[i])
            }
        }

        const arrayVencimentosTempRank = [...new Set(arrayVencimentosTemp2.map((valor) => convertDataToText(valor)))]
        setArrayVencimentos(arrayVencimentosTempRank)

        // RODA A FUNÇÃO DE SELEÇÃO DA DATA DE VENCIMENTO
        aoSelecionarVencimento(arrayVencimentosTempRank[0])

    },[])

    // TODA VEZ QUE A BASE COMENTÁRIOS MUDAR, ALTERA O TEXTO NA TELA
    useEffect(() =>{

        // ATUALIZA O COMENTÁRIO
        if(comentarioFirebase!=undefined){
            if(comentarioFirebase.texto!=undefined){
                if(comentarioFirebase.texto != ""){
                    if(comentarioFirebase.texto != comentario){
                        setComentario(comentarioFirebase.texto)
                        setLoading(false)
                    }
                }
            }
        }

    },[comentarioFirebase])

    // TODA VEZ QUE A VARIÁVEL DE BASE FILTRADA FOR ALTERADA
    useEffect(()=>{

        if(baseOpcoesAtivasFiltrada.length ===0){}else{

            // MONTA ARRAY ÚNICO COM TODOS OS CLIENTES QUE POSSUEM OPERAÇÃO ATIVA
            const arrayClientes = [...new Set(baseOpcoesAtivasFiltrada.map((valor) => valor.Titular))]
            setArrayClientes(arrayClientes)

            // MONTA ARRAY ÚNICO COM AS AÇÕES QUE ESTÃO SENDO UTILIZADAS
            const arrayOperacoes = [...new Set(baseOpcoesAtivasFiltrada.map((valor) => valor.acao))]

            // CRIA O OBJETO FINAL
            var baseDistOp = {}

            // ARRAY QUE ARMZENARÁ AS INFOS DE CADA AÇÃO
            var arrayAcoesTemp = []

            // LOOP NAS AÇÕES
            for(let i=0; i < arrayOperacoes.length; i++){

                baseDistOp[arrayOperacoes[i]] = {}

                // VARIÁVEIS QUE CONTAM OS TICKERS QUE ESTÃO OTM
                var tickersBad = 0
                var tickersWarning = 0
                var tickersOk = 0

                // FILTRA A BASE DE OPERAÇÕES ATIVAS PARA SOMENTE AQUELA AÇÃO
                var searchConditions = [
                    {
                    key: 'acao',
                    value: arrayOperacoes[i],
                    type: SearchType.EQ,
                    },
                ];

                var baseFiltradaAcao = filterData(baseOpcoesAtivasFiltrada, searchConditions);

                // VAR NOTIONAL TOTAL ACAO
                var notionalTotalAcao = 0

                // MONTA UM ARRAY DE TICKER EXCLUSIVOS
                const arrayTickers = [...new Set(baseFiltradaAcao.map((valor) => valor.Ativo))]

                // PARA CADA TICKER, VARRE A BASE FILTRADA
                for(let j=0; j < arrayTickers.length; j++){

                    baseDistOp[arrayOperacoes[i]][arrayTickers[j]] = {}

                    // FILTRA A BASE DE ACORDO COM TICKER
                    var searchConditions = [
                        {
                        key: 'Ativo',
                        value: arrayTickers[j],
                        type: SearchType.EQ,
                        },
                    ];
        
                    var baseFiltradaTicker = filterData(baseFiltradaAcao, searchConditions);

                    // VARRE TODOS OS CLIENTES NESSE TICKER
                    for(let k=0; k < arrayClientes.length; k++){

                        // FILTRA A BASE DE TICKER PRA CADA CLIENTE
                        var searchConditions = [
                            {
                            key: 'Titular',
                            value: arrayClientes[k],
                            type: SearchType.EQ,
                            },
                        ];
            
                        var baseFiltradaTickerCliente = filterData(baseFiltradaTicker, searchConditions);

                        // VARIÁVEIS DE CÁLCULO
                        var notionalTotal = 0
                        var quantidadeTotal = 0
                        var precoMedio = 0
                        var precoUltimo = 0
                        var retorno = 0

                        // VARRE TODAS AS OPERAÇÕES PRA ESSE TICKER E ESSE CLIENTE
                        for(let w=0; w < baseFiltradaTickerCliente.length; w++){

                            // CAPTURA A INFORMAÇÃO DO CLIENTE
                            var cliente = baseFiltradaTickerCliente[w]["Titular"]
                            var chave = baseFiltradaTickerCliente[w]["Chave"]
                            var callCoberta =  baseFiltradaTickerCliente[w]["callCoberta"]
                            var ticker = arrayTickers[i]
                            var tickerOpcao = baseFiltradaTickerCliente[w]["Ativo"]
                            var operacao = baseFiltradaTickerCliente[w]
                            var notionalDaOperacao = 0

                            // CAPTURA O ÚLTIMO PREÇO DA OPÇÃO
                            precoUltimo = baseFiltradaTickerCliente[w]["ultimo"]

                            // CAPTURA O ÚLTIMO PREÇO DA OPÇÃO DA BASE AO VIVO
                            if(baseRealTime === undefined){}else{
                                if(baseRealTime[tickerOpcao] === undefined){}else{
                                    precoUltimo = baseRealTime[tickerOpcao]["Último"]
                                }
                            }

                            // CAPTURA A QUANTIDADE DA OPERAÇÃO
                            var quantidade = 0
                            if(operacao.Lado === "V"){
                                quantidade = baseFiltradaTickerCliente[w]["Qtd Executada"]
                            }else{
                                quantidade = -1*baseFiltradaTickerCliente[w]["Qtd Executada"]
                            }

                            // CALCULA O PREÇO MÉDIO
                            if(w===0){
                                precoMedio = baseFiltradaTickerCliente[w]["Preco Medio"]
                                quantidadeTotal = quantidade
                            }else{
                                var precoPonderado = baseFiltradaTickerCliente[w]["Preco Medio"]*quantidade
                                precoMedio = ((precoMedio*quantidadeTotal) + precoPonderado)/(quantidadeTotal + quantidade)
                                quantidadeTotal = quantidadeTotal + quantidade
                            }

                            // CALCULA O NOTIONAL UTILIZADO
                            if(operacao.Lado === "V"){
                                if(operacao["Estrategia de Opcoes"]==="Trava"){}else{
                                    notionalDaOperacao = (operacao.strike * operacao["Qtd Executada"]) 
                                }
                            }

                            // SOMA NO NOTIONAL CONSUMIDO
                            notionalTotal = notionalTotal + notionalDaOperacao

                        }

                        // CALCULA O RETORNO
                        retorno = (precoMedio - precoUltimo)/precoMedio

                        // ATUALIZA OS VALORES NA BASE DE IMPRESSÃO
                        if(notionalTotal >0){
                            baseDistOp[arrayOperacoes[i]][arrayTickers[j]][arrayClientes[k]] = {}
                            baseDistOp[arrayOperacoes[i]][arrayTickers[j]][arrayClientes[k]]["notional"] = notionalTotal
                            baseDistOp[arrayOperacoes[i]][arrayTickers[j]][arrayClientes[k]]["precoCompra"] = precoMedio
                            baseDistOp[arrayOperacoes[i]][arrayTickers[j]][arrayClientes[k]]["precoUltimo"] = precoUltimo
                            baseDistOp[arrayOperacoes[i]][arrayTickers[j]][arrayClientes[k]]["retorno"] = retorno
                            baseDistOp[arrayOperacoes[i]][arrayTickers[j]][arrayClientes[k]]["chave"] = chave
                            baseDistOp[arrayOperacoes[i]][arrayTickers[j]][arrayClientes[k]]["callCoberta"] = callCoberta
                            baseDistOp[arrayOperacoes[i]][arrayTickers[j]][arrayClientes[k]]["quantidade"] = quantidadeTotal
                        }

                        // NOTIONAL TOTAL DA AÇÃO COMO MÉDIA POR CLIENTE AO INVÉS DE SOMA
                        // FILTRA A BASE DE OPERAÇÕES ATIVAS PARA SOMENTE AQUELA AÇÃO E VENCIMENTO
                        const arrayClientesAcao = [...new Set(baseFiltradaAcao.map((valor) => valor.Titular))]
                        notionalTotalAcao = notionalTotalAcao + notionalTotal/arrayClientesAcao.length

                    }

                    // SE O TICKER TIVER ALGUM NOTIONAL ATRIBUIDO
                    if(Object.keys(baseDistOp[arrayOperacoes[i]][arrayTickers[j]]).length > 0){
                        // VERIFICA SE O TICKER ESTÁ OTM
                        if(baseFiltradaTicker[0]["tipo"] === "PUT"){
                            if(baseFiltradaTicker[0]["strike"] < 0.95*baseFiltradaTicker[0]["precoAcao"]){
                                tickersOk = tickersOk + 1
                            }else if(baseFiltradaTicker[0]["strike"] < baseFiltradaTicker[0]["precoAcao"]){
                                tickersWarning = tickersWarning + 1
                            }else{
                                tickersBad = tickersBad + 1
                            }
                        }else if(baseFiltradaTicker[0]["tipo"] === "CALL"){
                            if(baseFiltradaTicker[0]["strike"] > 1.05*baseFiltradaTicker[0]["precoAcao"]){
                                tickersOk = tickersOk + 1
                            }else if(baseFiltradaTicker[0]["strike"] > baseFiltradaTicker[0]["precoAcao"]){
                                tickersWarning = tickersWarning + 1
                            }else{
                                tickersBad = tickersBad + 1
                            }
                        }
                    }

                }

                // POPULA O INPUT PARA O ARRAY DE AÇÕES
                var inputArrayAcoes = {
                    acao: baseFiltradaAcao[0]["acao"],
                    precoAcao: baseFiltradaAcao[0]["precoAcao"],
                    notional : notionalTotalAcao,
                    tickersBad:tickersBad,
                    tickersWarning: tickersWarning,
                    tickersOk:tickersOk,
                }

                arrayAcoesTemp.push(inputArrayAcoes)
                
            }

            arrayAcoesTemp.sort((a, b) => b.notional - a.notional);

            setArrayAcoes(arrayAcoesTemp)
            setJsonCompletoImpressao(baseDistOp)

        }

    },[baseOpcoesAtivasFiltrada])

    // FUNÇÃO DE AO SELECIONAR VENCIMENTO
    function aoSelecionarVencimento(vencimento){

        // ALTERA A VARIÁVEL DE ESTADO COM A DATA DE VENCIMENTO
        setDataVencimento(vencimento)

        var baseFiltradaTemp = []
        // FILTRA A BASE DE OPÇÕES ATIVAS
        for(let i=0; i < baseOpcoesAtivas.length; i++){
            if(convertDataToText(baseOpcoesAtivas[i]["vencimento"]) === vencimento){
                baseFiltradaTemp.push(baseOpcoesAtivas[i])
            }
        }

        // ALTERA A VARIÁVEL DE ESTADO DE OPÇÕES ATIVAS
        setBaseOpcoesAtivasFiltrada(baseFiltradaTemp)

    }

    // FUNÇÃO DE ATUALIZAR COMENTÁRIO MANUALMENTE
    const atualizaComentario = (evento) => {

        // PREVINE O DEFAULT DO FORMLÁRIO
        evento.preventDefault()

        //CAPTURA QUE FORMULÁRIO ESTAMOS FALANDO
        const form = evento.target.form
        var comentarioTemp = comentario

        // ATUALIZA O COMENTÁRIO NO FIREBASE
        setDoc(doc(db, "opcoesMap", "ComentarioGeral"), {
            texto: comentarioTemp
        }).then((ticker)=>{
        });
    }
    
    return(
        <section className={`${styles.secao}`}>
            {loading ? (<div className={`${styles.preloaderBox}`}><PreLoader/></div>) : (
                <div>
                    <h1>
                        <div className="sub-titulo-group">
                            <div>
                                <span className="material-symbols-outlined icon-title">paid</span>Mapa de operações
                            </div>
                        </div>
                    </h1>
                    <div className={`${styles.filtroVencimentoBox}`}>
                        <div className={`${styles.vencimentoFiltro}`}>Vencimento</div>
                        <div className={`${styles.listaDeVencimentos}`}>
                            {arrayVencimentos.map((vencimentos) =>{
                                // SE FOR IGUAL A DATA SELECIONADA RETORNA ISSO
                                if(vencimentos === dataVencimento){
                                    return(
                                        <div key={"botao" + vencimentos} onClick={() => aoSelecionarVencimento(vencimentos)} className={`${styles.diaVencimento} ${styles.diaVencimentoAtivo}`}>{vencimentos}</div>
                                    )
                                // SE FOR DIFERENTE RETORNA NORMAL
                                }else{
                                    return(
                                        <div key={"botao" + vencimentos} onClick={() => aoSelecionarVencimento(vencimentos)} className={`${styles.diaVencimento}`}>{vencimentos}</div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <div className={`${styles.commentsBox}`}>
                        <div className={`${styles.commentInput}`}>
                            <form id="form-comentario-geral" data-form="ComentarioOpOpcoesGeral">
                                <div className="row">
                                    <CampoForm 
                                        valor = {comentario}
                                        altura = "textoLargo"
                                        aoAlterado = {setComentario}
                                        nomeCampo= "Comentários Gerais" 
                                        campoId="comentarioOpGeral" 
                                        tipo="text" 
                                        dataCampo="comentarioOpOpcoes" 
                                        placeholder="" 
                                        dataMask=""
                                        required= {false}  
                                        largura= "12"
                                    />
                                </div>
                                <div className={`${styles.commentInputBtnBox}`}>
                                    <button type="submit" className={`${styles.commentInputButton} btn btn-primary`} data-campo="submitForm" onClick={atualizaComentario}>Enviar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div>
                        <Accordion>
                        {arrayAcoes.map((acao) => {

                            // DETERMINA O PREÇO DA AÇÃO
                            var precoAcao = 0
                            if(baseRealTime === undefined){}else{
                                if(baseRealTime[acao.acao] === undefined){
                                    precoAcao = RSLocale.format(acao.precoAcao.toFixed(2))
                                }else{
                                    precoAcao = RSLocale.format(baseRealTime[acao.acao]["Último"].toFixed(2))
                                }
                            }
                            return(
                                <Accordion.Item key={"item" + acao.acao} eventKey={acao.acao}>
                                    <Accordion.Header>
                                        <div className={`${styles.secaoAcao}`}>
                                            <div className={`${styles.nomeAcao}`}>{acao.acao}</div>
                                            <div className={`${styles.precoAcao}`}>P: {precoAcao}</div>
                                            <div className={`${styles.notAcao} ${styles["hide-mid"]}`}>N: {RSLocale.format(acao.notional.toFixed(0))}</div>
                                            <div className={`${styles.semaforo}`}>
                                                <div className={`${styles.boxitemOk} ${styles.itemSemaforo}`}>{acao.tickersOk}</div>
                                                <div className={`${styles.boxitemWarning} ${styles.itemSemaforo}`}>{acao.tickersWarning}</div>
                                                <div className={`${styles.boxitemBad} ${styles.itemSemaforo}`}>{acao.tickersBad}</div>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <TabelaAcao baseRealTime={baseRealTime} baseAcoesCallCoberta={baseAcoesCallCoberta[acao.acao]} baseOpcoesAtivas={baseOpcoesAtivasFiltrada} baseComentarios={baseComentarios} acao={acao} baseOperacoesAcao={jsonCompletoImpressao[acao.acao]}/>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                        </Accordion>
                    </div>
                </div>
            )}
        </section>
    )

}

export default MapaOpPorCliente