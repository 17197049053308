import React, { useState, useEffect, useRef } from 'react';
import Header from './componentes/Header';
import Cadastro from './paginas/Cadastro';
import LogIn from './paginas/LogIn';
import Footer from './componentes/Footer';
import Home from './paginas/Home';
import Perfil from './paginas/Perfil';
import Admin from './paginas/Admin';
import TermoEPolitica from './paginas/TermosEPolitica';
import OpcoesOp from './paginas/OpcoesOp';
import GestaoRisco from './paginas/GestaoRisco';
import { collectionGroup, getFirestore, doc, getDoc, getDocs, query, collection ,onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { app } from './config/firebase';
import PreLoader from './componentes/PreLoader';
import { Sidebar, Menu, MenuItem, SubMenu, SidebarHeader } from 'react-pro-sidebar';
import NavbarCustom from './componentes/NavbarCustom';
import WebSite from './paginas/WebSite';

function App() {

  // CONSTANTES E FUNÇÕES RELACIONADAS A AUTENTICAÇAO DO USUÁRIO E PRELOADER
  const [userObj, setUserObj] = useState({
    user:null,
    userUid:null,
    termoEPolitica:false
  })

  // VARIÁVEL DE ESTADO DE LOADING
  const [loading, setLoading] = useState(true);

  // VARIÁVEL DE ESTADO COM NOME DA PÁGINA QUE ESTÁ SENDO CARREGADA
  const [nomePagina, setNomePagina] = useState(null);

  // VARIÁVEIS DE ESTADO REFERENTES AS BASES DE DADOS UTILIZADAS
  const [baseDados, setBaseDados] = useState(null);
  const [baseDadosTotal, setBaseDadosTotal] = useState(null);
  const [baseDadosTotalUsers, setBaseDadosTotalUsers] = useState(null);
  const [baseConsultores, setBaseConsultores] = useState(null);
  const [baseHistoricoAcoes, setBaseHistoricoAcoes] = useState(null);

  // VARIÁVEIS DE REFERÊNCIA
  const secaoNossosClientes = useRef();
  const secaoPatrimonio = useRef();
  const secaoRentabilidade = useRef();
  const secaoResultadosMes = useRef();
  const secaoIndices = useRef();
  const secaoConciliacao = useRef();
  const secaoCarteira = useRef();
  const secaoMeusPremios = useRef();
  const secaoGestaoPerformance = useRef();
  const secaoMeuContato = useRef();

  useEffect(()=>{
    getAuth().onAuthStateChanged(async (usuario) => {
      setLoading(true)

      // CAPTURA A BASE DE CONSULTORES
      const db = getFirestore(app);
      const refConsultores = query(collection(db, "consultores"))
      const baseConsultoresSnapshot = await getDocs(refConsultores)
      var baseConsultoresTemp = []
      baseConsultoresSnapshot.forEach((doc) => {
        var key = doc.id
        var array = doc.data()
        baseConsultoresTemp[key] = array
      });

      setBaseConsultores(baseConsultoresTemp)

      // CAPTURA A BASE DE HISTÓRICO DAS AÇÕES
      const refAcoes = query(collection(db, "historicoAcoes"))
      const baseHistoricoAcoesSnapshot = await getDocs(refAcoes)
      var baseAcoesTemp = []
      baseHistoricoAcoesSnapshot.forEach((doc) => {
        var key = doc.id
        var array = doc.data()
        baseAcoesTemp[key] = array
      });

      setBaseHistoricoAcoes(baseAcoesTemp)

      // VALIDA SE O USUÁRIO ESTÁ LOGADO OU NÃO
      if (usuario) {

        if(usuario.email=="techumltda@gmail.com"){
          
          // BASE DE DADOS
          const refTodosOsDados = query(collectionGroup(db, "Carteira"))
          const baseTechSnapshot = await getDocs(refTodosOsDados)
          var baseDadosTotalTemp = []
          var keyAnterior = ""
          var array = []
          // LOOP QUE CONSTRÓI O ARRAY NACESSÁRIO
          baseTechSnapshot.forEach((doc) => {
            var key = doc._key.path.segments[6] // needs to change if the path changes
            var data = doc.id

            // SE A KEY FOR A MESMA, MANTÉM A CONSTRUÇÃO DA BASE
            if(key === keyAnterior){
              array[data] = doc.data()

            // SE A KEY MUDAR, REGISTRA O USUÁRIO ANTERIOR
            }else{
        
              // SE A KEY ANTERIOR FOR "", OU SEJA, O PRIMEIRO USUÁRIO, ENTÃO ADICIONA A CHAVE
              if(keyAnterior===""){
                array["chave"] = key
                array[data] = doc.data()

              // SE NÃO FOR O PRIMEIRO USUÁRIO ADICIONA A DATA ANTERIOR, A CHAVE ANTERIOR E COMEÇA A CRIAR O ARRAY DO PRÓXIMO
              }else{
                // DETECTA QUE MUDOU E GERA O ARRAY DO KEY ANTERIOR
                baseDadosTotalTemp.push(array)

                // COMEÇA A MONTAR O NOVO KEY
                array=[]
                array["chave"] = key
                array[data] = doc.data()
              }
            }
            keyAnterior = key
          });

          // COMPLEMENTAR AO LOOP ACIMA, ADICIONA A ÚLTIMA INFORMAÇÃO NA BASE DE DADOS
          baseDadosTotalTemp.push(array)

          // BASE DE EXTRATO DAS OPÇÕES
          /*const refTodosOsExtratosOp = query(collectionGroup(db, "ExtratoDeOpcoes"))
          const baseExtratoOpSnapshot = await getDocs(refTodosOsExtratosOp)
          var baseExtratoOpTotalTemp = []
          var keyAnterior = ""
          var arrayExtratoOp = []
          
          baseExtratoOpSnapshot.forEach((doc) => {
            var key = doc._key.path.segments[6] // needs to change if the path changes
            var data = doc.id

            // SE A KEY FOR A MESMA, MANTÉM A CONSTRUÇÃO DA BASE
            if(key === keyAnterior){
              arrayExtratoOp[data] = doc.data()

            // SE A KEY MUDAR, REGISTRA O USUÁRIO ANTERIOR
            }else{
        
              // SE A KEY ANTERIOR FOR "", OU SEJA, O PRIMEIRO USUÁRIO, ENTÃO ADICIONA A CHAVE
              if(keyAnterior===""){
                arrayExtratoOp["chave"] = key
                arrayExtratoOp[data] = doc.data()

              // SE NÃO FOR O PRIMEIRO USUÁRIO ADICIONA A DATA ANTERIOR, A CHAVE ANTERIOR E COMEÇA A CRIAR O ARRAY DO PRÓXIMO
              }else{
                // DETECTA QUE MUDOU E GERA O ARRAY DO KEY ANTERIOR
                baseExtratoOpTotalTemp.push(arrayExtratoOp)

                // COMEÇA A MONTAR O NOVO KEY
                arrayExtratoOp=[]
                arrayExtratoOp["chave"] = key
                arrayExtratoOp[data] = doc.data()
              }
            }
            keyAnterior = key

          })

          // COMPLEMENTAR AO LOOP ACIMA, ADICIONA A ÚLTIMA INFORMAÇÃO NA BASE DE DADOS
          baseExtratoOpTotalTemp.push(arrayExtratoOp)*/

          // BASE DE USUÁRIOS
          const refTodosOsUsuarios = query(collection(db, "users"))
          const baseTechSnapshotUsers = await getDocs(refTodosOsUsuarios)
          var baseDadosTotalUsersTemp = []
          baseTechSnapshotUsers.forEach((doc) => {
            var key = doc.id
            var array = doc.data()
            array["chave"] = doc.id
            baseDadosTotalUsersTemp.push(array)
          });

        }

        setBaseDadosTotal(baseDadosTotalTemp)
        setBaseDadosTotalUsers(baseDadosTotalUsersTemp)
        //setBaseOperacoesOp(baseExtratoOpTotalTemp)
        
        // CAPTURA OS DADOS DO USUÁRIO INDIVIDUAL
        const ref = query(collection(db,"dados", usuario.uid, "Carteira"))
        const refUsario = doc(db, "users", usuario.uid)
        const baseDadosCompleta = await getDocs(ref);
        const baseUsuario = await getDoc(refUsario);

        // AJUSTA O FORMATO DO ARRAY
        if(baseDadosCompleta && baseDadosCompleta.docs.length != 0){
          var baseDadosCompletaTemp = [];
          baseDadosCompleta.forEach((doc) => {
            var key = doc.id
            var array = doc.data()
            baseDadosCompletaTemp[key] = array
          });
          setBaseDados(baseDadosCompletaTemp)
        }

        // ATUALIZA AS INFOS DO USUÁRIO
        if(baseUsuario.data()){
          setUserObj((prev)=>({
            ...prev, 
            user:baseUsuario.data(),
            termoEPolitica:baseUsuario.data().termoEPolitica,
            userUid: usuario.uid
          }))
        }else{
          setUserObj((prev)=>({
            ...prev, 
            userUid: usuario.uid,
          }))
        }
        setLoading(false)
        return(usuario)

      } else {
        setUserObj((prev)=>({
          ...prev, 
          user:null,
          termoEPolitica:false,
          userUid:null
        }))
        setBaseDados(null)
        setLoading(false)
      }
    })
  },[])

  // ROTAS E SUAS PROTEÇÕES
  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/website" replace />;
    }else{
      if(userObj.termoEPolitica === false){
        return <Navigate to="/termoEPolitica" replace />;
      }else{
        return children;
      }
    }
  };

  const TermosEPoliticaRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/logIn" replace />;
    }else{
      if(userObj.termoEPolitica === false){
        return children;
      }else{
        return <Navigate to="/" replace />;
      }
    }
  };

  const ProtectedRouteAdmin = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/logIn" replace />;
    }else{
      if(userObj.termoEPolitica === false){
        return <Navigate to="/termoEPolitica" replace />;
      }else{
        if(user.email==="techumltda@gmail.com"){
          return children;
        }else{
          return <Navigate to="/" replace />;
        }
      }
    } 
  };

  const UnProtectedRoute = ({ user, children }) => {
    if (!user) {
      return children;
    }
    return <Navigate to="/" replace />;
  };

  // FUNÇÕES DE ALTERAÇÃO DA VARIÁVEL DE ESTADO USER
  function atualizaUser(novoUser){
    setUserObj((prev)=>({
      ...prev, 
      user:novoUser,
    }))
  }

  // ATUALIZA O TERMO E POLÍTICA
  function atualizaTermoEPolitica(update){
    setUserObj((prev)=>({
      ...prev, 
      termoEPolitica:update,
    }))
  }

  return (
    <div className={userObj.user === null || userObj.termoEPolitica === null || userObj.termoEPolitica === false ? "App" : "AppNav"}>
      <BrowserRouter>
        {userObj.user === null || userObj.termoEPolitica === null || userObj.termoEPolitica === false ?
          (<Header user={userObj.user}/>) : (
          <NavbarCustom 
            user={userObj.user} 
            secaoPatrimonio = {secaoPatrimonio}
            secaoRentabilidade = {secaoRentabilidade}
            secaoResultadosMes = {secaoResultadosMes}
            secaoIndices = {secaoIndices}
            secaoConciliacao = {secaoConciliacao}
            secaoCarteira = {secaoCarteira}
            secaoMeusPremios = {secaoMeusPremios}
            secaoMeuContato = {secaoMeuContato}
            secaoGestaoPerformance = {secaoGestaoPerformance}
            secaoNossosClientes = {secaoNossosClientes}
          />
        )}
        {loading ? (<PreLoader/>) : (
            <Routes>
              <Route path="/logIn" 
                element={
                  <UnProtectedRoute user={userObj.user}>
                    <LogIn mudaNomePagina={setNomePagina}/>
                  </UnProtectedRoute>
                } 
              />
              <Route path="/cadastro" 
                element={
                  <UnProtectedRoute user={userObj.user}>
                    <Cadastro baseConsultores={baseConsultores} mudaNomePagina={setNomePagina}/>
                  </UnProtectedRoute>
                }  
              />
              <Route path="/website" 
                element={
                  <UnProtectedRoute user={userObj.user}>
                    <WebSite mudaNomePagina={setNomePagina}/>
                  </UnProtectedRoute>
                }  
              />
              <Route path="*" element={
                  <UnProtectedRoute user={userObj.user}>
                    <WebSite mudaNomePagina={setNomePagina}/>
                  </UnProtectedRoute>
                }  
              />
              <Route path="/"
                element={
                  <ProtectedRoute user={userObj.user}>
                    <Home 
                      user={userObj.user} 
                      baseDados={baseDados} 
                      baseConsultores={baseConsultores} 
                      mudaNomePagina={setNomePagina} 
                      baseHistoricoAcoes={baseHistoricoAcoes}
                      secaoPatrimonio = {secaoPatrimonio}
                      secaoRentabilidade = {secaoRentabilidade}
                      secaoResultadosMes = {secaoResultadosMes}
                      secaoIndices = {secaoIndices}
                      secaoConciliacao = {secaoConciliacao}
                      secaoCarteira = {secaoCarteira}
                      secaoMeusPremios = {secaoMeusPremios}
                      secaoMeuContato = {secaoMeuContato}
                    />
                  </ProtectedRoute>
                }
              />
              <Route path="/perfil"
                element={
                  <ProtectedRoute user={userObj.user}>
                    <Perfil user={userObj.user} userUid={userObj.userUid} alteraUsuario={atualizaUser}  mudaNomePagina={setNomePagina}/>
                  </ProtectedRoute>
                }
              />
              <Route path="/admin"
                element={
                  <ProtectedRouteAdmin user={userObj.user}>
                    <Admin 
                      user={userObj.user} 
                      baseDados={baseDados} 
                      baseDadosTotal={baseDadosTotal} 
                      baseDadosTotalUsers={baseDadosTotalUsers} 
                      baseConsultores={baseConsultores} 
                      mudaNomePagina={setNomePagina} 
                      baseHistoricoAcoes={baseHistoricoAcoes}
                      secaoPatrimonio = {secaoPatrimonio}
                      secaoRentabilidade = {secaoRentabilidade}
                      secaoResultadosMes = {secaoResultadosMes}
                      secaoIndices = {secaoIndices}
                      secaoConciliacao = {secaoConciliacao}
                      secaoCarteira = {secaoCarteira}
                      secaoMeusPremios = {secaoMeusPremios}
                      secaoMeuContato = {secaoMeuContato}
                      secaoGestaoPerformance = {secaoGestaoPerformance}
                      secaoNossosClientes = {secaoNossosClientes}
                    />
                  </ProtectedRouteAdmin>
                }
              />
              <Route path="/termoEPolitica"
                element={
                  <TermosEPoliticaRoute user={userObj.user}>
                    <TermoEPolitica user={userObj.user} userUid={userObj.userUid} alteraUsuario={atualizaUser} baseDados={baseDados} aceite={atualizaTermoEPolitica} baseConsultores={baseConsultores} mudaNomePagina={setNomePagina}/>
                  </TermosEPoliticaRoute>
                }
              />
              <Route path="/opcoesOp"
                element={
                  <ProtectedRouteAdmin user={userObj.user}>
                    <OpcoesOp user={userObj.user} baseHistoricoAcoes={baseHistoricoAcoes}/>
                  </ProtectedRouteAdmin>
                }
              />
              <Route path="/gestaoRisco"
                element={
                  <ProtectedRouteAdmin user={userObj.user}>
                    <GestaoRisco user={userObj.user} baseDadosTotal={baseDadosTotal}/>
                  </ProtectedRouteAdmin>
                }
              />
            </Routes>       
        )}
        <Footer user={userObj.user}/>
      </BrowserRouter>
    </div>
  );
  
}

export default App ; 
