import CanvasJSReact from "../../canvasjs/canvasjs.react";
import "../../paginas/Home/Home.css";
import "../../index.css";
import TabelaResultados from "../TabelaResultados";
import Accordion from 'react-bootstrap/Accordion';
import { useState, useEffect, useRef} from 'react';
import CardAcoes from "../CardAcoes";
import LinhaCarteira from "../LinhaDistCarteira"
import Dividends from "../../animacoes/Dividends.json"
import Moeda from "../../animacoes/Moeda.json"
import Grafico from "../../animacoes/Grafico.json"
import Filme from "../../animacoes/Filme.json"
import Lottie from "lottie-react";
import ShareCarteiraDrillDown from "./drilldown";
import { convertDataToText, convertTextoToData } from "../../paginas/Admin/convertData";
import { filterData, SearchType } from 'filter-data';
import GraficoLimpo from "../GraficoLimpo";
import IndiceDetalhe from "../IndiceDetalhe";
import desvioPadrao from "./desvioPadrao";
import YoutubeEmbed from "../YoutubeVideo";
import ModalApp from "../Modal";
import ResumoMesAnterior from "../ResumoMesAnterior";
import CardImagem from "../CardImagem";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Carteira = (props) => {

    // VARIÁVEL DE FONTE DOS DADOS
    const fonte = "sans-serif"

    // VARIÁVEL QUE AMARRA SE A TELA É CLIENTE OU ADMIN
    var admin = true

    // VARIÁVEIS DE CORPO DO MODAL
    var titulo = "Gráfico do índice no tempo"
    var corpo = "Aqui estará o gráfico"

    // VARIÁVEIS DE MODAL
    const [modalObj, setModalObj] = useState({
        show:false,
        titulo:"",
        corpo:"",
        tipo:""
    })

    // FUNÇÃO PARA CHAMADA DE MODAL
    function callModal(props){
        setModalObj((prev)=>({
            ...prev,
            show:true,
            titulo:titulo,
            corpo:corpo,
            tipo:props
        }))
    }

    // FUNÇÃO DE OCULTAR E EXIBIR MODAL
    function exibirOcultarModal(statusModal){
        setModalObj((prev)=>({
            ...prev,
            show:statusModal
        }))
    }

    // VARIÁVEIS REF
    const modeloSwitchRef = useRef();
    const folhaDeIndicesRef = useRef();
    const volESharpRef = useRef();

    // VARIÁVEIS DE ESTADO
    const [labelfontsize, setlabelfontsize] = useState(12) // VARIÁVEL PARA OCULTAR LABELS DOS GRÁFICOS   
    const [moduloCardAcoes, setModuloCardAcoes] = useState("info") // VARIÁVEL QUE DETERMINA O TIPO DE INFO QUE SERÁ IMPRESSA NO CARD AÇÃO
    const [modeloProjecao, setModeloProjecao] = useState("standard") // VARIÁVEL QUE DETERMINA A PROEJEÇÃO SERÁ NO MODELO STANDARD OU FUNDO 

    useEffect(() => {

        // ESCONDE OQUE TEM QUE ESCONDER SE O USUÁRIO NÃO FOR ADMIN
        // OPTAMOS POR TRAZER TODAS AS OPÇÕES PARA O CLIENTE
        if(props.acesso === "cliente"){
            modeloSwitchRef.current.classList.remove("hide")
        }else{
            modeloSwitchRef.current.classList.remove("hide")
        }
        
        // CONSTANTES PARA VISUALIZAÇÃO DE OCULTAR DADOS NA TELA
        const btnViewValor = document.querySelectorAll("[data-hide-valor]");
        const listaValores = document.querySelectorAll("[data-valor]");
        
        for (let contador = 0; contador < btnViewValor.length; contador++) {

            const botao = btnViewValor[contador];

            if(botao){

                botao.onclick = function(){
                    esconderValores(botao.dataset.hideValor)
                }

            }

        }

        // FUNÇÃO QUE ATRIBUI AOS OLHOS NA TELA A FUNÇÃO DE OCULTAR E EXIBIR VALORES
        const esconderValores = (estado) => {
            if(estado){

                if(estado === "visivel"){

                    // AJUSTA O BOTÃO PARA NOVO SÍMBOLO
                    for (let contador = 0; contador < btnViewValor.length; contador++) {
                        btnViewValor[contador].innerHTML = "visibility_off"
                        btnViewValor[contador].dataset.hideValor = "invisivel"
                    }
                    // OCULTA AS INFOS IMPRESSAS
                    for (let contador = 0; contador < listaValores.length; contador++) {
                        listaValores[contador].classList.add('hide')
                    }
                    // OCULTA OS RÓTULOS DE GRÁFICOS
                    setlabelfontsize(0)

                }else{
                    // AJUSTA O BOTÃO PARA NOVO SÍMBOLO
                    for (let contador = 0; contador < btnViewValor.length; contador++) {
                        btnViewValor[contador].innerHTML = "visibility"
                        btnViewValor[contador].dataset.hideValor = "visivel"
                    }
                    // OCULTA AS INFOS IMPRESSAS
                    for (let contador = 0; contador < listaValores.length; contador++) {
                        listaValores[contador].classList.remove('hide')
                    }
                    // OCULTA OS RÓTULOS DE GRÁFICOS
                    setlabelfontsize(12)

                }

            }
        }

        // ESCONDE A PARTE DE AÇÕES CASO NÃO HAJAM AÇÕES PARA SEREM IMPRESSAS
        var blocoAcoes = document.getElementsByClassName("blocoAcoes")
        if(dadosListaAcoes.length===0){
            blocoAcoes[0].classList.add("hideBox");
        }else{
            blocoAcoes[0].classList.remove("hideBox");
        }

    })

    // VARIÁVEL PARA CÁLCULO DA TIR
    const { irr } = require('node-irr')

    // BASE DE AÇÕES
    const baseHistoricoAcoes = props.baseHistoricoAcoes

    // BASE DE DADOS
    const baseDados = props.baseDados

    // DECLARAÇÃO DAS VARIÁVEIS DE IMPRESSÃO
    const dadosProjecaoPatrimonio =[];
    const dadosProjecaoIPCA = [];
    const dadosProjecaoCDI = [];
    const dadosProjecaoBOVA = [];
    const dadosResumoBase = [];
    const dadosFlutuacaoPatrimonioBase = [];
    const dadosShareCarteiraBase = [];
    const dadosResultados = [];
    const dadosTabelaResultados = [];
    const dadosListaAcoes = [];
    const dadosListaAcoesTop10 = [];
    const dadosCotas = [];
    const dadosCotasMercado = []
    const dadosRendimentoVol = [];
    const base = baseDados;

    // VARIÁVEIS QUE IRÃO ARMAZENAR OS VALORES PARA O PRIMEIRO PONTO DE PARTIDA DOS GRÁFICOS NO TEMPO
    var primeiraData = null
    var primeiroDado = null

    // FUNÇÃO DE ESCOLHA DO MÓDULO DE EXIBIÇÃO DA AÇÃO
    function mudaModuloAcao(e){

        let elementPai = e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
        var elementAtivo = null
        var elementInativo = null
        var tituloAtivo = null
        var tituloInativo = null

        if(elementPai.dataset.moduloacao === "info"){
            setModuloCardAcoes("info")
            elementAtivo = document.getElementsByClassName("detalheAcoesBotaoInfo")
            elementInativo = document.getElementsByClassName("detalheAcoesBotaoGraf")
            tituloAtivo = document.getElementsByClassName("detalheAcoesBotaoTituloInfo")
            tituloInativo = document.getElementsByClassName("detalheAcoesBotaoTituloGraf")
            elementAtivo[0].classList.add("moduloAtivo");
            elementInativo[0].classList.remove("moduloAtivo");
            tituloAtivo[0].classList.add("detalheAcoesBotaoTituloAtivo");
            tituloInativo[0].classList.remove("detalheAcoesBotaoTituloAtivo")
        }else if(elementPai.dataset.moduloacao === "graf"){
            setModuloCardAcoes("graf")
            elementAtivo = document.getElementsByClassName("detalheAcoesBotaoGraf")
            elementInativo = document.getElementsByClassName("detalheAcoesBotaoInfo")
            tituloAtivo = document.getElementsByClassName("detalheAcoesBotaoTituloGraf")
            tituloInativo = document.getElementsByClassName("detalheAcoesBotaoTituloInfo")
            elementAtivo[0].classList.add("moduloAtivo");
            elementInativo[0].classList.remove("moduloAtivo");
            tituloAtivo[0].classList.add("detalheAcoesBotaoTituloAtivo");
            tituloInativo[0].classList.remove("detalheAcoesBotaoTituloAtivo")
        }else{
            
        }
    }

    // FUNÇÃO QUE DETERMINA O MODELO DE PATRIMÔNIO A SER IMPRESSO
    function handleChangeModelo(e) {
        if(e.target.checked === true){
            setModeloProjecao("asset")
            // setModeloProjecaoTexto("Standard View")
        }else{
            setModeloProjecao("standard")
            // setModeloProjecaoTexto("Asset View")
       }
    }

    // FUNÇÃO DE EXIBIÇÃO DO MODAL DE DETALHE SOBRE VOLATILIDADE
    function volDetalhe(){
        titulo = "Entendendo Volatilidade"
        corpo  = (
            <div>
                <div style={{color: "gray"}}>
                    A <b>volatilidade</b> pode ser definida como uma medida estatística da <b>dispersão dos retornos para um determinado ativo financeiro</b> ou até mesmo um índice de mercado.
                    <br></br>
                    Dessa maneira, <b>quanto maior a variação do preço de determinado ativo em um espaço definido de tempo, maior é a volatilidade</b> daquele papel e, por consequência, maiores são os riscos de se aumentar ou diminuir um patrimônio atrelado ao mercado de capitais no período analisado.
                    <br></br>
                    Para uma primeira visão mais didática e simples confira abaixo um vídeo abordando o tema:
                </div>
                <div style={{height: "300px", marginTop: "10px"}}>
                    <YoutubeEmbed embedId="OTm2pFF7CIk"/>
                </div>
            </div>
        )
        callModal()
    }

    // FUNÇÃO DE EXIBIÇÃO DO MODAL DE DETALHE SOBRE SHARP
    function sharpDetalhe(){
        titulo = "Entendendo Índice Sharpe"
        corpo  = (
            <div>
                <div style={{color: "gray"}}>
                    <b>O Índice de Sharpe</b> é um indicador que permite a <b>avaliação relativa entre o risco e o retorno de um determinado investimento.</b> Ele tem o objetivo de mensurar qual é a relação entre o retorno excedente ao ativo livre de risco e sua volatilidade.
                    <br></br>
                    Tecnicamente, o <b>índice indica qual % esperado de retorno</b> acima de investimentos livres de risco para cada <b>1% de volatilidade da carteira.</b>
                    <br></br>
                    Em outras palavras, uma carteira com <b>mais volatilidade deveria ser capaz de trazer mais retornos</b>, e o índice sharpe serve para avaliar a relação entre esses 2 fatores.
                    <br></br>
                    Em termos comparativos, <b>quanto maior o valor do índice, melhor.</b>
                    <br></br>
                    Confira abaixo um vídeo com explicação mais técnica e detalhada sobre o índice e sua importância:
                </div>
                <div style={{height: "300px", marginTop: "10px"}}>
                    <YoutubeEmbed embedId="G4ctPvQA2p4"/>
                </div>
            </div>
        )
        callModal()
    }

    // FUNÇÃO DE CLIQUE NO GRÁFICO DE CONCILIAÇÃO
    function ConciliacaoChartDrilldownHandler(e){
        // CAPTURA O NOME DO CAMPO
        var nome = e.dataPoint.nome

        // VARIÁVEL PARA IMPRESSÃO DO GRÁFICO
        var dadosGrafDrillDownConciliacao = []
        // VARRE A BASE TEMPORAL DE CONCILIAÇÃO E MONTA O ARRAY DE IMPRESSÃO DO GRÁFICO
        for (let i = 0; i < dadosFlutuacaoPatrimonioBase.length; i++) {

            // CAPTURA A DATA
            var data = new Date(dadosFlutuacaoPatrimonioBase[i][0])

            // INPUT DE CÁLCULO
            var input = {}
            var campoAcumulado = 0

            // LOOP NOS CAMPOS DE DADOS
            for (let j = 0; j < dadosFlutuacaoPatrimonioBase[i].length; j++) {

                // CÁLCULO DO CAMPO ACUMULADO
                if(j===0 || dadosFlutuacaoPatrimonioBase[i][j]["isCumulativeSum"]===true || dadosFlutuacaoPatrimonioBase[i][j]["isIntermediateSum"]===true){}else{campoAcumulado = dadosFlutuacaoPatrimonioBase[i][j]["y"] + campoAcumulado}
                
                // VERIFICA SE É O CAMPO DESEJADO
                if(dadosFlutuacaoPatrimonioBase[i][j]["nome"] === nome){

                    // SE FOR UM CAMPO ACUMULADO
                    if(dadosFlutuacaoPatrimonioBase[i][j]["isCumulativeSum"]===true || dadosFlutuacaoPatrimonioBase[i][j]["isIntermediateSum"]===true){
                        input = {
                            x: data,
                            y: campoAcumulado
                        }

                    }else{
                        input = {
                            x: data,
                            y: dadosFlutuacaoPatrimonioBase[i][j]["y"]
                        }
                    }
    
                }

            }

            dadosGrafDrillDownConciliacao.push(input)

        }

        // CRIAS AS OPÇÕES DE IMPRESSÃO DO GRÁFICO
        var grafOptions = {
            height:300,
            animationEnabled: true,
            backgroundColor: "transparent",
            legend:{
                fontColor: "rgb(150,150,150)",
                verticalAlign:"top",
                fontFamily: fonte,
                fontWeight: "normal"
            },
            toolTip:{
                shared: true,
                fontColor: "gray",
                fontFamily: fonte,
                contentFormatter: function (e) {
                    let RSLocale = Intl.NumberFormat('pt-BR');             
                    var content = "<strong>" + e.entries[0].dataPoint.x.toLocaleDateString() +"</strong>" + "<table>";
                        content += "<tr/>"; 
                        content += "<th style='padding-right:10px'><span style = 'color:" + e.entries[0].dataSeries.color + "'>" + e.entries[0].dataSeries.name + ": </span></th> " + "<th><span style='color:gray'>R$ " + RSLocale.format(parseFloat(e.entries[0].dataPoint.y).toFixed(2)) + "</span></th>";
                        content += "<tr/>";
                        content += "</table>"
                    return content;
                }
                
            },
            title:{
                text: "",
                padding:0, //20
                fontSize:0, //20
                fontColor: "rgba(102,205,170,1.0)",
                fontFamily: fonte
            },
            axisY: {
                title: "",
                gridThickness: 0,
                labelFontSize:0.0000000000001,
                lineThickness:0,
                tickThickness: 0,
                labelFontFamily: fonte
            },
            axisX:{
                gridThickness: 0,
                margin: 10,
                tickThickness: 0,
                labelFontColor: "rgba(150,150,150,1.0)",
                lineColor: "rgba(150,150,150,1.0)",
                lintThickness: 0.5,
                intervalType: "month",
                labelFontFamily: fonte,
                labelFontSize:10,
                valueFormatString:"MMM YYYY"
            },
            data: [{
                indexLabelFormatter: function ( e ) {

                    // CALCULA A DIFERENÇA PRO MÊS ANTERIOR
                    let RSLocale = Intl.NumberFormat('pt-BR');   
                    var baseCompleta = e.dataSeries.dataPoints
                    var dataPonto = e.dataPoint.x
                    var valorAtual = 0
                    var valorAnterior = 0
                    var dif = 0
                    for (let i = 0; i < baseCompleta.length; i++) {

                        if(baseCompleta[i].x === dataPonto){
                            if(i===0){
                                valorAtual = baseCompleta[i].y
                                dif = valorAtual - valorAnterior
                            }else{
                                valorAtual = baseCompleta[i].y
                                valorAnterior = baseCompleta[i-1].y
                                dif = valorAtual - valorAnterior
                            }
                        }

                    }

                    // AJUSTA A ORDEM DE GRANDEZA DA DIF CONFORME TAMANHO DO NÚMERO
                    var difAjustada = ""
                    if(dif === 0){}else{
                        if(Math.abs(dif) < 1000){
                            difAjustada = "Δ " +RSLocale.format(parseFloat(dif).toFixed(0))
                        }else{
                            if(Math.abs(dif) < 100000){
                                difAjustada = "Δ " + RSLocale.format(parseFloat(dif/1000).toFixed(0)) + "k"
                            }else{
                                difAjustada = "Δ " + RSLocale.format(parseFloat(dif/1000000).toFixed(2)) + "M"
                            }
                        }
                    }

                    return(difAjustada)

                },
                indexLabelFontColor: "gray",
                indexLabelFontSize:8,
                type: "column",
                axisYIndex: 1,
                name:nome,
                showInLegend: true,
                legendText: nome,
                color: "rgb(102,205,170)",
                markerSize: 5,
                xValueFormatString: "MM/YYYY",
                dataPoints: dadosGrafDrillDownConciliacao
            }]
        }

        // CHAMA O MODAL COM GRÁFICO IMPRESSO
        titulo = nome + " no tempo"
        corpo  =             
        <div style={{height:300+"px"}}>
            <GraficoLimpo id="conciliacaoNoTempoGrafico" options={grafOptions}/>
        </div>

        callModal()

    }

    // LOOP NO OBJETO PRINCIPAL VINDO DO FIRESTORE
    // AQUI SÃO CRIADAS TODAS AS BASES DE IMPRESSÃO DA TELA
    for (let elementKey in base) {
        const element = base[elementKey];

        // CONVERTER O DADO X EM DATA ANTES DE INSERIR NO ARRAY
        // SPLIT O TEXTO
        const dataObj = elementKey.split("-")
        const ano = parseInt(dataObj[2]) 
        const mesInicial = parseInt(dataObj[1])-1
        const mes = parseInt(dataObj[1]) // DATA FINAL DO MÊS ANTERIOR = 01 DO MÊS SEGUINTE

        // DATA INICIAL DA BASE CASO ESSA DATA SEJA A MAIS ANTIGA DA BASE
        const dataInicial = new Date(ano,mesInicial,1)

        // DATA DE FECHAMENTO DO MÊS ANTERIOR VIRA DATA DE ABERTURA DO MÊS SEGUINTE NOS GRÁFICOS DE TEMPO
        const data = new Date(ano,mes,1)

        // DATA DE COMPETÊNCIA PARA CONTABILIZAÇÃO DOS PRÊMIOS
        const dataResultados = new Date(ano, mes-1, 1)

        // COMPARA A DATA ATUAL COM A PRIMEIRA DATA PARA ATUALIZAR A DATA MAIS ANTIGA DO GRÁFICO
        if(primeiraData===null || primeiraData > dataInicial){
            primeiraData = dataInicial

            // SE A BASE NÃO TIVER SIDO FILTRADA
            if(props.baseInteiraData === true){
                primeiroDado = element["PatrimonioInicial"]
            }else{
                primeiroDado = element["PatrimonioAtual"]
            }

        }

        // POPULA BASES DE DADOS PARA GRÁFICOS DE VARIAÇÃO NO TEMPO
        const inputProjecaoPatrimonio = {}
        if(modeloProjecao==="standard"){
            // INPUT DE PROJEÇÃO
            inputProjecaoPatrimonio.x= data;
            inputProjecaoPatrimonio.y= element["Soma Total"];
            inputProjecaoPatrimonio.dataResultado = dataResultados;
            inputProjecaoPatrimonio.comissoesAcumuladas = element["Comissoes"];
            inputProjecaoPatrimonio.entradasSaidas= element["Entrada e Saida Total"];
            inputProjecaoPatrimonio.rendimentoBOVA=element["IBOV (%)"];
            inputProjecaoPatrimonio.rendimentoIPCA=element["IPCA (%)"];
            inputProjecaoPatrimonio.rendimentoCDI=element["CDI (%)"];
            inputProjecaoPatrimonio.cota=element["Valor da Cota"]
            
            // ARRAY PARA CÁLCULO DE VOLATILIDADE E SHARP
            dadosCotas.push({
                cota: element["Valor da Cota"],
                data: dataResultados
            })

        }else if(modeloProjecao==="asset"){
            // INPUT DE PROJEÇÃO
            inputProjecaoPatrimonio.x= data;
            inputProjecaoPatrimonio.y= element["Saldo Visivel na XP"];
            inputProjecaoPatrimonio.dataResultado = dataResultados;
            inputProjecaoPatrimonio.comissoesAcumuladas = element["Comissoes"];
            inputProjecaoPatrimonio.entradasSaidas= element["Entrada e Saida Total"];
            inputProjecaoPatrimonio.rendimentoBOVA=element["IBOV (%)"];
            inputProjecaoPatrimonio.rendimentoIPCA=element["IPCA (%)"];
            inputProjecaoPatrimonio.rendimentoCDI=element["CDI (%)"];
            inputProjecaoPatrimonio.cota=element["Valor da Cota Asset"]

            // ARRAY PARA CÁLCULO DE VOLATILIDADE E SHARP
            dadosCotas.push({
                cota: element["Valor da Cota Asset"],
                data: dataResultados
            })

            // ARRAY DE COTAS NA MARCAÇÃO A MERCADO
            dadosCotasMercado.push({
                cota: element["Valor da Cota Asset"],
                data: dataResultados
            })
        }

        dadosProjecaoPatrimonio.push(inputProjecaoPatrimonio)
        const inputResultado = {x: data, y: element["Resultado das Opcoes"] }
        dadosResultados.push(inputResultado)

        // POPULA AS INFORMAÇÕES FIXAS QUE SERÃO EXIBIDAS NA TELA
        // FUNÇÃO PARA FORMATAÇÃO EM MOEDA   
        let RSLocale = Intl.NumberFormat('pt-BR');
        if(modeloProjecao==="standard"){
            dadosResumoBase.push({
                data: dataResultados,
                patrimonioInicial:"R$ " + RSLocale.format(parseInt(element["PatrimonioInicial"])),
                patrimonioFinal:"R$ " + RSLocale.format(parseInt(element["PatrimonioAtual"])),
                periodoAtivo:parseFloat(element["Qnt de meses ativos"]),
                cdi: parseFloat(element["CDI (%)"]),
                acumuladoAno:(parseFloat(element["AcumuladoAno"])*100).toFixed(1),
                mediaMensal:(((1+parseFloat(element["Rentabilidade desde o inicio"]))**(1/parseFloat(element["Qnt de meses ativos"]))-1)*100).toFixed(1),
                acumuladoTrimestre:(parseFloat(element["AcumuladoTrimestre"])*100).toFixed(1),
                acumuladoMes:(parseFloat(element["AcumuladoMes"])*100).toFixed(1),
                acumuladoInicio:(parseFloat(element["Rentabilidade desde o inicio"])*100).toFixed(0),
            })
        }else if(modeloProjecao==="asset"){
            dadosResumoBase.push({
                data: dataResultados,
                patrimonioInicial:"R$ " + RSLocale.format(parseInt(element["PatrimonioInicial"])),
                patrimonioFinal:"R$ " + RSLocale.format(parseInt(element["Saldo Visivel na XP"])),
                periodoAtivo:parseFloat(element["Qnt de meses ativos"]),
                cdi: parseFloat(element["CDI (%)"]),
                acumuladoAno:(parseFloat(element["AcumuladoAno"])*100).toFixed(1),
                mediaMensal:(((1+parseFloat(element["Rentabilidade desde o inicio"]))**(1/parseFloat(element["Qnt de meses ativos"]))-1)*100).toFixed(1),
                acumuladoTrimestre:(parseFloat(element["AcumuladoTrimestre"])*100).toFixed(1),
                acumuladoMes:(parseFloat(element["AcumuladoMes"])*100).toFixed(1),
                acumuladoInicio:(parseFloat(element["Rentabilidade desde o inicio"])*100).toFixed(0),
            })
        }

        // POPULA A BASE DE CONCILIAÇÃO DO PATRIMÔNIO
        dadosFlutuacaoPatrimonioBase.push([
            data,
            { label: "Valor Depositado", nome:"Depositos",  y: element["Depositos"] },
            { label: "Retiradas", nome:"Retiradas", y: element["Retiradas"] },
            { label: "Entradas Líquidas", isIntermediateSum: true, nome:"Entradas Líquidas", color: "rgba(200,200,200,1.0)", y:element["Depositos"] + element["Retiradas"]},
            { label: "Prêmios", y: element["Premios"], nome:  "Prêmios"},
            { label: "Comissões",  y: element["Comissoes"], nome:  "Comissões" },
            { label: "Taxas e Multas",  y: element["Taxas"], nome: "Taxas e Multas"},
            { label: "Flutuação Carteira", nome:"Flutuação Carteira", y: element["Flutuacao da Carteira"] },
            { label: "Saldo Total", nome:"Saldo Total", isCumulativeSum: true, color: "rgba(200,200,200,1.0)" },
            { label: "Operações com Opções", nome:"Operações com Opções",  y: element["Valor em Opcoes"] },
            { label: "Saldo XP", nome:"Saldo XP",  isCumulativeSum: true, patrimonio: element["Saldo Visivel na XP"]}
        ])

        // POPULA O SHARE DAS CARTEIRAS
        dadosShareCarteiraBase.push([
            data,
            { y: 0, color: "rgb(250,250,250)", name: "Opções", percentual: 0, value: "- R$ " + RSLocale.format(element["Opcoes (R$)"].toFixed(0)) },
            { y: element["Acao (R$)"], name: "Ação", color: "rgb(0,94,94)", percentual: (element["Acao (R$)"]/parseInt(element["PatrimonioAtual"])*100).toFixed(0) },
            { y: element["Conta Investimento (R$)"], name: "Conta", color: "rgb(0,139,139)", percentual: (element["Conta Investimento (R$)"]/parseInt(element["PatrimonioAtual"])*100).toFixed(0) },
            { y: element["Renda Fixa (R$)"], name: "Renda Fixa", color: "rgb(102,205,170)", percentual: (element["Renda Fixa (R$)"]/parseInt(element["PatrimonioAtual"])*100).toFixed(0) },
            { y: element["FIIs (R$)"], name: "FII", color: "#5ec9cc", percentual: (element["FIIs (R$)"]/parseInt(element["PatrimonioAtual"])*100).toFixed(0) },
            { y: element["Proventos (R$)"], name: "Proventos", color: "rgb(150,150,150)", percentual: (element["Proventos (R$)"]/parseInt(element["PatrimonioAtual"])*100).toFixed(0) },
            { y: element["Lancamentos Futuros (R$)"], name: "Lanç. Futuros", color: "rgb(200,200,200)", percentual: (element["Lancamentos Futuros (R$)"]/parseInt(element["PatrimonioAtual"])*100).toFixed(0) }
        ])

        // POPULA TABELA DE IMPRESSÃO DOS PRÊMIOS MENSAIS
        dadosTabelaResultados.push({
                data: dataResultados,
                nocional: RSLocale.format(parseFloat(element["Notional (R$)"]).toFixed(0)),
                premio: RSLocale.format(parseFloat(element["Resultado das Opcoes"]).toFixed(0)),
                percentual: parseFloat(element["Resultado das Opcoes (%)"]*100).toFixed(1)     
        })

        // POPULA OS DADOS DE IMPRESSÃO DAS AÇÕES
        // LOOP NA LISTA DE AÇÕES
        Object.keys(element["Ações"]).forEach(acao => {
            // ADICIONA OS DADOS NECESSÁRIOS PARA IMPRESSÃO DO CARD
            dadosListaAcoes.push({
                pm: element["Ações"][acao]["Preco Medio"],
                pt: element["Ações"][acao]["Preco Teto"],
                pa: element["Ações"][acao]["Preco Atual"],
                panterior: element["Ações"][acao]["Preco Mes Anterior"],
                pmvenda:element["Ações"][acao]["Preco Medio Vendida no Mes"],
                pmcompra:element["Ações"][acao]["Preco Medio Comprada no Mes"],
                setor: element["Ações"][acao]["Setor"],
                unidades: element["Ações"][acao]["Quantidade"],
                unidadesMesAnterior:element["Ações"][acao]["Quantidade"] - element["Ações"][acao]["Quantidade Comprada no Mes"] + element["Ações"][acao]["Quantidade Vendida no Mes"],
                unidadesVendidas:element["Ações"][acao]["Quantidade Vendida no Mes"],
                unidadesCompradas:element["Ações"][acao]["Quantidade Comprada no Mes"],
                ticker: acao,
                valorTotal: element["Ações"][acao]["Valor total"],
                variacao: element["Ações"][acao]["PeL"],
                variacaoPercentual: element["Ações"][acao]["PeL"]/(element["Ações"][acao]["Quantidade"]*element["Ações"][acao]["Preco Medio"]),
                dataAtualizacao: convertDataToText(data),
                percentualNaCarteira: element["Ações"][acao]["Valor total"] / element["Acao (R$)"]
            })
        })

    };

    // É PRECISO RANKEAR O ARRAY PRIMEIREMENTE POR DATA ANTES DE PUBLICAR O MESMO
    dadosProjecaoPatrimonio.sort((a, b) => a.x - b.x);
    dadosResumoBase.sort((a, b) => a.data - b.data);
    dadosTabelaResultados.sort((a, b) => a.data - b.data);
    dadosResultados.sort((a, b) => a.x - b.x);
    dadosResultados.shift();
    dadosTabelaResultados.shift();
    dadosFlutuacaoPatrimonioBase.sort((a, b)=> {
        if (a[0] === b[0]) {
            return 0;
        }
        else {
            return (a[0] < b[0]) ? -1 : 1;
        }
    });
    dadosShareCarteiraBase.sort((a, b)=> {
        if (a[0] === b[0]) {
            return 0;
        }
        else {
            return (a[0] < b[0]) ? -1 : 1;
        }
    });
    dadosCotas.sort((a,b)=>a.data - b.data)

    // FILTRO DA BASE DE AÇÕES
    if(dadosListaAcoes.length !== 0){
        dadosListaAcoes.sort((a, b) => convertTextoToData(a.dataAtualizacao) - convertTextoToData(b.dataAtualizacao));
        var dataParaFiltro = dadosListaAcoes[(dadosListaAcoes.length-1)]["dataAtualizacao"]
        var searchConditions = [
            {
            key: 'dataAtualizacao',
            value: dataParaFiltro,
            type: SearchType.EQ,
            },
        ];
    
        var dadosListaAcoesData = filterData(dadosListaAcoes, searchConditions)
    
        // LIMITA A BASE DE AÇÕES PARA 10 NO MÁXIMO
        var contadorDeAcoes = 0
        dadosListaAcoesData.sort((a, b) => b.valorTotal - a.valorTotal);
        for (let acao in dadosListaAcoesData) {
            if(dadosListaAcoesData[acao]["unidades"]>0){
                contadorDeAcoes = contadorDeAcoes + 1
                if(contadorDeAcoes<11){
                    dadosListaAcoesTop10[acao] = JSON.parse(JSON.stringify(dadosListaAcoesData[acao]))
                }
            }
        }
    }

    // MONTA A BASE TIR CASO A BASE TENHA SIDO FILTRADA
    const arrayTir = []
    const arrayTirUltimoMes = []

    // CAPTURA O PENÚLTIMO DADO COMO ENTRADA PARA CÁLCULO DO RENDIMENTO DO ÚLTIMO MÊS CASO HAJA MAIS DE 1 PERÍODO
    if(dadosProjecaoPatrimonio.length > 1){
        arrayTirUltimoMes.push(dadosProjecaoPatrimonio[dadosProjecaoPatrimonio.length-2]["y"])
    }

    // CONSTRÓI AS BASES NO TEMPO PARA DRILLDOWN DO GRÁFICO DE CONCILIAÇÃO DE PATRIMÔNIO
    var comissoesAcumuladasAnterior = 0
    var comissoesAcumuladas = 0
    var comissoesMes = 0
    
    // LOOP NOS DADOS DE PROJEÇÃO PARA CÁLCULO DA TIR E TAMBÉM CONSTRUÇÃO DAS CURVAS NO TEMPO
    for (let i = 0; i < dadosProjecaoPatrimonio.length; i++) {

        // ADICIONA O VALOR DE PARTIDA
        if(i===0){

            // ATUALIZA OS VALORES PARA CÁLCULO DAS COMISSÕES MÊS A MÊS
            comissoesAcumuladasAnterior = dadosProjecaoPatrimonio[i]["comissoesAcumuladas"]
            comissoesMes = dadosProjecaoPatrimonio[i]["comissoesAcumuladas"]

            // ADICIONA O PATRIMÔNIO INICIAL NA CURVA DA TIR
            arrayTir.push(dadosProjecaoPatrimonio[i]["y"])

            // CASO SEJA O PRIMEIRO VALOR DO ARRAY E A BASE SEJA FILTRADA, ADICIONA O VALOR DE PARTIDA
            //if(props.baseInteiraData===false){
            // ADICIONA O PATRIMÔNIO INICIAL NAS CURVAS DO TEMPO
            const inputProjecaoCDI = {x: dadosProjecaoPatrimonio[i]["x"], y: dadosProjecaoPatrimonio[i]["y"] }
            dadosProjecaoCDI.push(inputProjecaoCDI)
    
            const inputProjecaoBova = {x: dadosProjecaoPatrimonio[i]["x"], y: dadosProjecaoPatrimonio[i]["y"] }
            dadosProjecaoBOVA.push(inputProjecaoBova)
    
            const inputProjecaoIPCA = {x: dadosProjecaoPatrimonio[i]["x"], y: dadosProjecaoPatrimonio[i]["y"] }
            dadosProjecaoIPCA.push(inputProjecaoIPCA)
            //}

        // CASO SEJA O ÚTLIMO VALOR
        }else if(i===(dadosProjecaoPatrimonio.length-1)){ 

            // ATUALIZA OS VALORES PARA CÁLCULO DAS COMISSÕES MÊS A MÊS
            comissoesAcumuladasAnterior = dadosProjecaoPatrimonio[i-1]["comissoesAcumuladas"]
            comissoesAcumuladas = dadosProjecaoPatrimonio[i]["comissoesAcumuladas"]
            comissoesMes = comissoesAcumuladas - comissoesAcumuladasAnterior

            // CASO SEJA UMA BASE DE SOMENTE UM MÊS O ÚLTIMO DADO SERÁ TAMBÉM O PRIMEIRO
            // CASO SEJA O ÚLTIMO VALOR DA BASE, FAZ UM TRATAMENTO DIFERENCIADO PARA CONSTRUÇÃO DO ARRAY DA TIR
            var ultimoValorTir = dadosProjecaoPatrimonio[i]["y"]*(-1) + dadosProjecaoPatrimonio[i]["entradasSaidas"] - comissoesMes
            arrayTir.push(ultimoValorTir)
            arrayTirUltimoMes.push(ultimoValorTir)

            // CALCULA AS CURVAS BASEADO NOS SEUS RENDIMENTOS CONSIDERANDO ENTRADAS E SAÍDAS
            const patrCDI = parseFloat((dadosProjecaoCDI[(i-1)]["y"])*(1+parseFloat(dadosProjecaoPatrimonio[i]["rendimentoCDI"])))+parseFloat(dadosProjecaoPatrimonio[(i)]["entradasSaidas"])
            const inputProjecaoCDI = {x: dadosProjecaoPatrimonio[i]["x"], y: patrCDI}
            dadosProjecaoCDI.push(inputProjecaoCDI)
    
            const patrBOVA = parseFloat((dadosProjecaoBOVA[(i-1)]["y"])*(1+parseFloat(dadosProjecaoPatrimonio[i]["rendimentoBOVA"])))+parseFloat(dadosProjecaoPatrimonio[(i)]["entradasSaidas"])
            const inputProjecaoBova = {x: dadosProjecaoPatrimonio[i]["x"], y: patrBOVA}
            dadosProjecaoBOVA.push(inputProjecaoBova)
    
            const patrIPCA = parseFloat((dadosProjecaoIPCA[(i-1)]["y"])*(1+parseFloat(dadosProjecaoPatrimonio[i]["rendimentoIPCA"])))+parseFloat(dadosProjecaoPatrimonio[(i)]["entradasSaidas"])
            const inputProjecaoIPCA = {x: dadosProjecaoPatrimonio[i]["x"], y: patrIPCA}
            dadosProjecaoIPCA.push(inputProjecaoIPCA)

        // CASO SEJA ALGUM VALOR INTERMEDIÁRIO
        }else{

            // ATUALIZA OS VALORES PARA CÁLCULO DAS COMISSÕES MÊS A MÊS
            comissoesAcumuladasAnterior = dadosProjecaoPatrimonio[i-1]["comissoesAcumuladas"]
            comissoesAcumuladas = dadosProjecaoPatrimonio[i]["comissoesAcumuladas"]
            comissoesMes = comissoesAcumuladas - comissoesAcumuladasAnterior

            // POPULA A BASE PARA CÁLCULO DO TIR
            arrayTir.push(dadosProjecaoPatrimonio[i]["entradasSaidas"] - comissoesMes)                
        
            // CALCULA AS CURVAS BASEADO NOS SEUS RENDIMENTOS CONSIDERANDO ENTRADAS E SAÍDAS
            const patrCDI = parseFloat((dadosProjecaoCDI[(i-1)]["y"])*(1+parseFloat(dadosProjecaoPatrimonio[i]["rendimentoCDI"])))+parseFloat(dadosProjecaoPatrimonio[i]["entradasSaidas"] - comissoesMes)
            const inputProjecaoCDI = {x: dadosProjecaoPatrimonio[i]["x"], y: patrCDI}
            dadosProjecaoCDI.push(inputProjecaoCDI)
    
            const patrBOVA = parseFloat((dadosProjecaoBOVA[(i-1)]["y"])*(1+parseFloat(dadosProjecaoPatrimonio[i]["rendimentoBOVA"])))+parseFloat(dadosProjecaoPatrimonio[i]["entradasSaidas"] - comissoesMes)
            const inputProjecaoBova = {x: dadosProjecaoPatrimonio[i]["x"], y: patrBOVA}
            dadosProjecaoBOVA.push(inputProjecaoBova)
    
            const patrIPCA = parseFloat((dadosProjecaoIPCA[(i-1)]["y"])*(1+parseFloat(dadosProjecaoPatrimonio[i]["rendimentoIPCA"])))+parseFloat(dadosProjecaoPatrimonio[i]["entradasSaidas"] - comissoesMes)
            const inputProjecaoIPCA = {x: dadosProjecaoPatrimonio[i]["x"], y: patrIPCA}
            dadosProjecaoIPCA.push(inputProjecaoIPCA)
        }

    }

    // AJUSTES NECESSÁRIOS NAS BASES DE IMPRESSÃO
    const cont = dadosResumoBase.length -1 // POSIÇÃO DA ÚLTIMA DATA MAIS RECENTE NO ARRAY
    const dadosResumo = dadosResumoBase[cont] // DADO COMPLETO MAIS RECENTE
    const dadosFlutuacaoPatrimonio = JSON.parse(JSON.stringify(dadosFlutuacaoPatrimonioBase[cont])) // DADO COMPLETO MAIS RECENTE
    const dadosFlutuacaoPatrimonioAnterior = JSON.parse(JSON.stringify(dadosFlutuacaoPatrimonioBase[cont-1]))
    const dadosShareCarteira = dadosShareCarteiraBase[cont]; // DADO COMPLETO MAIS RECENTE
    const dataMesAtivo = dadosFlutuacaoPatrimonio.shift(); // ELIMINA O CAMPO DATA QUE É O PRIMEIRO DA LISTA
    dadosFlutuacaoPatrimonioAnterior.shift(); // ELIMINA O CAMPO DATA QUE É O PRIMEIRO DA LISTA
    const provisaoOpcoes = dadosShareCarteira[1]["value"]; // CAPTURA O VALOR PROVISIONADO DE OPÇÕES
    dadosShareCarteira.shift(); // ELIMINA A DATA DE ATUALIZAÇÃO
    dadosShareCarteira.shift(); // REMOVENDO O CAMPO OPÇÕES QUE NÃO DEVE ENTRAR NO GRÁFICO

    var acumuladoCDI = 1
    // CALCULA A RENTABILIDADE ACUMULADA DO CDI
    for (let i = 0; i < dadosResumoBase.length; i++) {
        acumuladoCDI = acumuladoCDI * (1+dadosResumoBase[i]["cdi"])
    }

    // CONSTRÓI A BASE DE RENDIMENTOS DA CARTEIRA PARA CÁLCULO DA VOLATILIDADE
    var rendimentoAnterior = 0
    for (let i = 0; i < dadosProjecaoPatrimonio.length; i++) {  
        
        var rendimento = 0
        var rendimentoAcumulado = 0
        var arrayTirVol = []
        var input = 0

        if(i===0){}else{

            // VARRE TODOS OS MESES ANTERIORES ATÉ O MÊS EM QUESTÃO
            for (let j = 0; j <= i; j++) {

                // CALCULA O VALOR DAS COMISSÕES MENSAIS
                if(j===0){
                    input = dadosProjecaoPatrimonio[(j)]["y"]

                }else if(j===i){
                    // ATUALIZA OS VALORES PARA CÁLCULO DAS COMISSÕES MÊS A MÊS
                    comissoesAcumuladasAnterior = dadosProjecaoPatrimonio[j-1]["comissoesAcumuladas"]
                    comissoesAcumuladas = dadosProjecaoPatrimonio[j]["comissoesAcumuladas"]
                    comissoesMes = comissoesAcumuladas - comissoesAcumuladasAnterior
                    input = dadosProjecaoPatrimonio[(j)]["y"]*(-1) + dadosProjecaoPatrimonio[(j)]["entradasSaidas"] - comissoesMes
                    
                }else{
                    // ATUALIZA OS VALORES PARA CÁLCULO DAS COMISSÕES MÊS A MÊS
                    comissoesAcumuladasAnterior = dadosProjecaoPatrimonio[j-1]["comissoesAcumuladas"]
                    comissoesAcumuladas = dadosProjecaoPatrimonio[j]["comissoesAcumuladas"]
                    comissoesMes = comissoesAcumuladas - comissoesAcumuladasAnterior
                    input = dadosProjecaoPatrimonio[(j)]["entradasSaidas"] - comissoesMes 

                }

                arrayTirVol.push(input)

            }

            // CALCULA O RENDIMENTO ACUMULADO DESSE MÊS
            rendimentoAcumulado = (1 + irr(arrayTirVol))**(arrayTirVol.length-1)
            rendimentoAnterior = 1 + rendimentoAnterior
            rendimento = rendimentoAcumulado/rendimentoAnterior - 1

            // RENDIMENTO ANTERIOR PASSA A SER O ATUAL
            rendimentoAnterior = rendimentoAcumulado - 1
            dadosRendimentoVol.push(rendimento)
        }
    }

    // POPULA VARIÁVEIS DE IMPRESSÃO
    var tituloAtivos = dadosResumo.patrimonioFinal
    var premiosAcumulados =  parseInt(dadosFlutuacaoPatrimonio[3]["y"]/1000) + "K" // PRÊMIOS NO ARRAY DE RECONCILIAÇÃO
    dadosResumo.periodoAtivo = dadosProjecaoPatrimonio.length-1 // ATUALIZA O NÚMERO DE PERÍODOS DE ACORDO COM FILTRO
    dadosResumo.mediaMensal = (irr(arrayTir)*100).toFixed(1)
    dadosResumo.acumuladoInicio = ((((1+dadosResumo.mediaMensal/100) ** (dadosResumo.periodoAtivo))-1)*100).toFixed(0)
    dadosResumo.acumuladoMes = (irr(arrayTirUltimoMes)*100).toFixed(1) 
    dadosResumo.volatilidade = (desvioPadrao(dadosRendimentoVol)*100).toFixed(1) // VOLATILIDADE DOS RENDIMENTOS MENSAIS
    dadosResumo.acumuladoCDI = acumuladoCDI

    // CÁLCULO DAS VARIÁVEIS ANUALIZADAS PARA ÍNDICE SHARP
    var anualizadoCarteira = ((1+irr(arrayTir))**12)-1
    var anualizadoCDI = ((dadosResumo.acumuladoCDI)**(12/(dadosResumoBase.length-1)))-1
    var anualizadoVol = desvioPadrao(dadosRendimentoVol) * (12**(0.5))
    dadosResumo.sharpe = ((anualizadoCarteira - anualizadoCDI)/anualizadoVol).toFixed(2)

    if(dadosResumo.mediaMensal==="NaN" || dadosResumo.acumuladoMes==="NaN"){
        dadosResumo.mediaMensal = ""
        dadosResumo.acumuladoInicio = ""
        dadosResumo.acumuladoMes = ""
    }

    // CONFIGURAÇÃO DOS GRÁFICOS DE IMPRESSÃO
    const optionsProjecaoPatrimonio = {
		animationEnabled: true,
        height:370,
		backgroundColor: "transparent",
		legend:{
			fontColor: "rgb(150,150,150)",
            verticalAlign:"top",
            fontFamily: fonte,
            fontWeight: "normal"
		},
        toolTip:{
            shared: true,
            fontColor: "gray",
            fontFamily: fonte,
            contentFormatter: function (e) {
                let RSLocale = Intl.NumberFormat('pt-BR');             
				var content = "<strong>" + e.entries[0].dataPoint.x.toLocaleDateString() +"</strong>" + "<table>";
				for (var i = 0; i < e.entries.length; i++) {
                    content += "<tr/>";
					content += "<th style='padding-right:10px'><span style = 'color:" + e.entries[i].dataSeries.color + "'>" + e.entries[i].dataSeries.name + ": </span></th> " + "<th><span style='color:gray'> R$ " + RSLocale.format(parseInt(e.entries[i].dataPoint.y)) + "</span></th>";
					content += "<tr/>";
				}
                content += "</table>"
				return content;
            }
            
        },
		title:{
			text: "Evolução Patrimônio (R$)",
			padding:0, //20
			fontSize:0, //20
			fontColor: "rgba(102,205,170,1.0)",
			fontFamily: fonte
		},
		axisY: {
			title: "",
			gridThickness: 0,
			labelFontSize:0.0000000000001,
			lineThickness:0,
			tickThickness: 0,
            labelFontFamily: fonte
		},
		axisX:{
			gridThickness: 0,
			margin: 10,
			tickThickness: 0,
			labelFontColor: "rgba(150,150,150,1.0)",
			lineColor: "rgba(150,150,150,1.0)",
			lintThickness: 0.5,
            intervalType: "month",
            labelFontFamily: fonte,
            labelFontSize:10,
            valueFormatString:"MMM YYYY"
		},
		data: [{
			type: "splineArea",
            name: "Carteira",
			showInLegend: true,
			legendText: "Carteira",
			color: "rgba(102,205,170,.7)",
			markerSize: 5,
			xValueFormatString: "MM/YYYY",
			yValueFormatString: "R$ #,###,.K",
			dataPoints: dadosProjecaoPatrimonio
		}, {
			type: "spline",
            name:"IPCA",
			showInLegend: true,
			legendText: "IPCA",
			color: "rgba(65,105,225,.4)",
			markerSize: 5,
			xValueFormatString: "MM/YYYY",
			yValueFormatString: "R$ #,###,.K",
			dataPoints: dadosProjecaoIPCA
		}, {
			type: "spline",
            name:"CDI",
			showInLegend: true,
			legendText: "CDI",
			color: "rgba(255,255,0,.4)",
			markerSize: 5,
			xValueFormatString: "MM/YYYY",
			yValueFormatString: "R$ #,###,.K",
			dataPoints: dadosProjecaoCDI
		}, {
			type: "spline",
            name:"BOVA",
			showInLegend: true,
			legendText: "BOVESPA",
			color: "rgba(255,127,80,.4)",
			markerSize: 5,
			xValueFormatString: "MM/YYYY",
			yValueFormatString: "R$ #,###,.K",
			dataPoints: dadosProjecaoBOVA
		}]
    }

    const optionsConciliacao = {
        animationEnabled: true,
        backgroundColor: "transparent",
        legend:{
            fontColor: "rgb(150,150,150)",
            fontFamily: fonte,
        },
        title:{
            text: "Flutuação do Petrimônio (R$)",
            padding:20,
            fontSize:0,
            fontColor: "rgba(102,205,170,1.0)",
            fontFamily: fonte
        },
        toolTip:{
            fontFamily: fonte,
            contentFormatter: function (e) {
                let RSLocale = Intl.NumberFormat('pt-BR');
                var content = "<table/>"  ;              
				content += "<th style='padding-right:10px'><span style='color: gray '>" + e.entries[0].dataPoint.label + ": </span></th> " + "<th><span style='color:gray'> R$ " + RSLocale.format(parseInt(e.entries[0].dataPoint.y)) + "</span></th>";
				content += "<tr/>";
                content += "</table>"
				return content;
            }
        },
        axisY: {
            title: "",
            minimum:0,
            gridThickness: 0,
            labelFontSize:0.0000000000001,
            lineThickness:0,
            tickThickness: 0,
            labelFontFamily: fonte,
        },
        axisX:{
            gridThickness: 0,
            margin: 10,
            tickThickness: 0,
            labelFontColor: "rgba(150,150,150,1.0)",
            lineColor: "rgba(150,150,150,1.0)",
            lintThickness: 0.5,
            labelFontSize:12,
            interval:1,
            labelFontFamily: fonte,
        },
        data: [{
            click: ConciliacaoChartDrilldownHandler,
            cursor: "pointer",
            type: "waterfall",
            color: "rgba(200,200,200,0,7)",
            risingColor: "rgba(102,205,170,.7)",
            fallingColor: "rgba(255,127,80,.4)",
            lineThickness:1,
            lineColor:"rgba(200,200,200,1)",
            indexLabel: "{y}",
            indexLabelFontSize: labelfontsize,
            indexLabelPlacement: "outside",
            indexLabelFontColor: "rgb(150,150,150)",
            indexLabelFontFamily: fonte,
            dataPoints: dadosFlutuacaoPatrimonio,
            indexLabelFormatter:function (e) {               
                    var content = parseInt(e.dataPoint.y/1000) + " k";
                    return content;
                }            
        }]
    }

    const optionsResultados = {
        animationEnabled: true,
		backgroundColor: "transparent",
		legend:{
			fontColor: "rgb(150,150,150)",
            verticalAlign: "top",
            fontSize:0,
            fontFamily: fonte
		},
        toolTip:{
            shared: true,
            fontColor: "gray",
            fontFamily: fonte,
            contentFormatter: function (e) {              
                let RSLocale = Intl.NumberFormat('pt-BR');             
				var content = "<strong>" + e.entries[0].dataPoint.x.toLocaleDateString() +"</strong>" + "<table>";
				for (var i = 0; i < e.entries.length; i++) {
                    content += "<tr/>";
					content += "<th style='padding-right:10px'><span style = 'color:" + e.entries[i].dataSeries.color + "'>" + e.entries[i].dataSeries.name + ": </span></th> " + "<th><span style='color:gray'> R$ " + RSLocale.format(parseInt(e.entries[i].dataPoint.y)) + "</span></th>";
					content += "<tr/>";
				}
                content += "</table>"
				return content;
            }
        },
		title:{
			text: "",
			padding:0,
			fontSize:0,
			fontColor: "rgba(102,205,170,1.0)",
			fontFamily: fonte
		},
		axisY: {
			title: "",
			gridThickness: 0,
			labelFontSize:0.0000000000001,
			lineThickness:0,
			tickThickness: 0,
            labelFontFamily: fonte
		},
		axisX:{
			gridThickness: 0,
			margin: 10,
			tickThickness: 0,
            labelFontSize:0.0000000000001,
			labelFontColor: "rgba(150,150,150,1.0)",
			lineColor: "rgba(150,150,150,0.0)",
			lintThickness: 0,
            labelFontFamily: fonte
		},
		data: [{
			type: "column",
            name: "Prêmios",
			showInLegend: true,
            indexLabelFontFamily: fonte,
            indexLabel: "{y}",
            indexLabelFontSize: labelfontsize,
            indexLabelFontColor:"rgba(150,150,150,1.0)",
			legendText: "Prêmios",
			color: "rgba(102,205,170,.7)",
			markerSize: 5,
            indexLabelFormatter:function (e) {       
                if(e.dataPoint.y === 0){
                    var content = ""
                }else{
                    var content = parseInt(e.dataPoint.y/1000) + "K";
                }       
                return content;
            },
			xValueFormatString: "MM/YYYY",
			yValueFormatString: "R$#,##0.##",
			dataPoints: dadosResultados
		}]
    }

    return(
        <div className="container-md">
            <ModalApp tipo ={modalObj.tipo} show={modalObj.show} change={exibirOcultarModal} titulo={modalObj.titulo} corpo ={modalObj.corpo}/>
            <section id="secaoPatrimonio" ref={props.secaoPatrimonio}>
                <h1>
                    <div className="sub-titulo-group">
                        <div>
                            <span className="material-symbols-outlined icon-title">trending_up</span>Evolução do Patrimônio
                        </div>
                        <span className="material-symbols-outlined pointer" data-hide-valor="visivel">visibility</span>
                    </div>
                </h1>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="box-shadow saldo-disponivel">
                            <h3>Patrimônio Inicial</h3>
                            <p data-valor>{dadosResumo.patrimonioInicial}</p>
                        </div>
                        <div className="box-shadow saldo-em-produtos">
                            <h3>Patrimônio atual</h3>
                            <p data-valor>{dadosResumo.patrimonioFinal}</p>
                        </div>
                        <div className="imagem-patrimonio">
                            <CardImagem imagemSrc="imagens/ImagemAnalisando.png">
                            </CardImagem>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8">
                        <div className="box-shadow box-grafico">
                            <div className="radioBoxPatrimonio" id="radioBoxPatrimonio">
                                <div ref={modeloSwitchRef} className="form-check form-switch hide">
                                    <input className="form-check-input" type="checkbox" id="modeloProjecaoCheck" name="modeloProjecaoSwitch" value="yes" onChange={handleChangeModelo} />
                                    <label className="form-check-label" htmlFor="modeloProjecaoCheck">Marcação a mercado</label>
                                </div>
                            </div>
                            <div id="grafico-projecao" className="grafico-margin">
                                <GraficoLimpo id="graf-proj-patrimonio" options={optionsProjecaoPatrimonio}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="secaoRentabilidade" ref={props.secaoRentabilidade}>    
                <h1>
                    <div className="sub-titulo-group">
                        <div>
                            <span className="material-symbols-outlined icon-title">monitoring</span>Rentabilidade e Volatilidade
                        </div>
                    </div>
                </h1>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="boxRentabilidade box-shadow">
                            <div className="boxGraficoTempo">
                                <div className="graficoTempo">Período com estratégia ativa:</div>
                                <div className="textoTempo"><span className="numeroTextoTempo">{dadosResumo.periodoAtivo}&nbsp;</span> meses</div>
                            </div>
                            <div className="boxRendimentoSempre">
                                <div className="rendimentoSempre">
                                    <div className="tituloRendimentoSempre">Rentabilidade<br /> acumulada</div>
                                    <div className="valorRendimentoSempre">{dadosResumo.acumuladoInicio}%</div>
                                </div>
                                <div className="rendimentoMedioMensal">
                                    <div className="tituloRendimentoMedioMensal">Média<br />  mensal</div>
                                    <div className="valorRendimentoMedioMensal">{dadosResumo.mediaMensal}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                    {admin ? 
                        <div ref={volESharpRef} className="boxVolESharp box-shadow">
                            <div className="volESharp">
                                <div className="tituloSharpIconLine"> 
                                    <div className="tituloSharpIcon" onClick={volDetalhe}><Lottie animationData={Filme}/></div>
                                </div>
                                <div className="tituloVolESharp">
                                    <div className="tituloVolESharpTexto">Volatilidade</div>
                                </div>
                                <div className="valorVolatilidade"><span>{dadosResumo.volatilidade}</span>%</div>
                            </div>
                            <div className="sharp">
                                <div className="tituloSharpIconLine">
                                    <div className="tituloSharpIcon" onClick={sharpDetalhe}><Lottie animationData={Filme}/></div>
                                </div>
                                <div className="tituloSharp" >
                                    <div className="tituloSharpTexto">Índice<br></br>Sharp</div>
                                </div>
                                <div className="valorRendimentoSempre"><span>{dadosResumo.sharpe}</span></div>
                            </div>
                        </div>
                    : (
                        <div className="box-premios-acumulados">
                            <div className="box-direita-premios"><Lottie animationData={Dividends}/></div>
                            <div className="box-esquerda-premios">
                                <div className="titulo-de-premios-acumulados">Prêmios<br></br>Acumulados</div>
                                <div className="valor-premios-acumulados">{premiosAcumulados}</div>
                            </div>
                        </div>
                    )}
                    </div>
                    <div className="col-0 col-md-0 col-lg-4">
                        <div className="boxDuvidas">
                            <div className="boxDuvidasTexto"><span>Para melhor <em>entendimento</em>, clique nos <em>ícones</em> de Volatilidade e Sharpe</span></div>
                            <div className="boxDecorativaDuvidas"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="secaoResultadoDoMes" ref={props.secaoResultadosMes}>
                <h1>
                    <div className="sub-titulo-group">
                        <div>
                            <span className="material-symbols-outlined icon-title">price_check</span>Resultados do mês
                        </div>
                    </div>
                </h1>
                <div className="row">
                    <ResumoMesAnterior dadosAcoes={dadosListaAcoesData} data={dataMesAtivo} dadosConciliacaoUltimo={dadosFlutuacaoPatrimonio} dadosConciliacaoPenultimo={dadosFlutuacaoPatrimonioAnterior}/>
                </div>
            </section>
            <section id="secaoDesempenhoIndices" ref={props.secaoIndices}>
                <h1>
                    <div className="sub-titulo-group">
                        <div>
                            <span className="material-symbols-outlined icon-title">list</span>Desempenho e Índices
                        </div>
                    </div>
                </h1>
                {admin ?
                    <div ref={folhaDeIndicesRef} className="row linhaDeIndices">
                        <IndiceDetalhe indice="Carteira" baseIndices={dadosProjecaoPatrimonio} dadosResumo={dadosResumo} baseRendimentosCarteira={dadosRendimentoVol}/>
                        <IndiceDetalhe indice="BOVA" baseIndices={dadosProjecaoPatrimonio}/>
                        <IndiceDetalhe indice="CDI" baseIndices={dadosProjecaoPatrimonio}/>
                        <IndiceDetalhe indice="IPCA" baseIndices={dadosProjecaoPatrimonio}/>
                    </div>
                :(
                    <div></div>
                )}
            </section>
            <section id="secaoConciliacaoPatrimonio" ref={props.secaoConciliacao}>
                <h1><span className="material-symbols-outlined icon-title">bar_chart</span>Conciliação de Patrimônio</h1>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="box-shadow box-grafico">
                            <div id="grafico-conciliacao-patrimonio" className="grafico-margin">
                                <GraficoLimpo id="graf-proj-conciliacao" options={optionsConciliacao}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="secaoCarteira" ref={props.secaoCarteira}>
                <h1><span className="material-symbols-outlined icon-title">account_balance_wallet</span>Minha Carteira</h1>
                <div className="row graficos-projecao">
                    <div className="col-12">
                        <div className="box-shadow boxDistCarteira">
                            <div className="row boxDistCarteiraCorpo">
                                <div className="col-12 col-md-6 boxGraficoCarteira">
                                    <div id="grafico-carteira" className="graficoCarteira">
                                        <ShareCarteiraDrillDown dados ={dadosShareCarteiraBase} fontSize={labelfontsize} patrimonio={dadosResumo.patrimonioFinal}/>
                                    </div>
                                    <div className="provisaoOpcoes">Provisão de operação com opções: <b>{provisaoOpcoes}</b></div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="boxLegendaCarteira">
                                        {dadosShareCarteira.map(produto =>                 
                                            <LinhaCarteira key={produto.name + "ID"} dados={produto}  chave={produto.name + "ID"} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Accordion className="blocoAcoes" defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Detalhamento Ações</Accordion.Header>
                            <Accordion.Body>
                                <div className="detalheAcoesBotoes">
                                    <div className="detalheAcoesBotaoBox">
                                        <div className="detalheAcoesBotaoTituloInfo detalheAcoesBotaoTituloAtivo">Posição</div>
                                        <div className="detalheAcoesBotaoInfo moduloAtivo" data-moduloacao = "info"><Lottie animationData={Moeda} onClick={(e) => mudaModuloAcao(e)}/></div>
                                    </div>
                                    <div className="detalheAcoesBotaoBox">
                                        <div className="detalheAcoesBotaoTituloGraf">Histórico</div>
                                        <div className="detalheAcoesBotaoGraf" data-moduloacao = "graf"><Lottie animationData={Grafico} onClick={(e) => mudaModuloAcao(e)}/></div>
                                    </div>
                                    <div className="detalheAcaoStripe"></div>
                                </div>
                                <div className="row">
                                    {dadosListaAcoesTop10.map(acao =>
                                        <div key={acao.ticker + "Box"} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <CardAcoes key={acao.ticker} ticker={acao.ticker} acaoDetalhes={acao} modulo={moduloCardAcoes} dadosHistoricoAcao={baseHistoricoAcoes[acao.ticker]}/>
                                        </div>
                                    )}      
                                </div>       
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </section>
            <section id="secaoPremios" ref={props.secaoMeusPremios}>
                <h1><span className="material-symbols-outlined icon-title">payments</span>Meus Prêmios</h1>
                <div className="row graficos-projecao">
                    <div className="col-12 col-md-6">
                        <div className="box-shadow box-grafico-carteira grafico-premios">
                            <div id="grafico-carteira" className="grafico-margin">
                                <GraficoLimpo id="graf-proj-resultados" options = {optionsResultados}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 graficos-secundarios-projecao graficos-secundarios-scroll">
                        <TabelaResultados dados = {dadosTabelaResultados} />
                    </div>
                </div>
            </section>
        </div>
    )

}

export default Carteira