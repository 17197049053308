import styles from './WebSit.module.css';
import { Carousel } from 'react-bootstrap';
import YoutubeEmbed from '../../componentes/YoutubeVideo';
import { LinkContainer } from 'react-router-bootstrap';

var textoQuemSomos = "Nós somos a TechUm, referência em investimentos e tecnologia"

const WebSite = (props) => {

    var arrayTecnologia = [
        {"nome":"Bloomberg", "img" : "finance_mode", "text": "Obtemos informações online e detalhadas do mercado para auxiliar em nossa gestão de risco."},
        {"nome":"Python", "img" : "query_stats", "text": "Ferramentas estatísticas sofisticadas nos ajudam a refiniar nossos métodos."},
        {"nome":"Google", "img" : "verified_user", "text": "Utilizamos da cloud e estrutura providas pela Google para oferecer um app seguro e completo."},
        {"nome":"Neologica", "img" : "trending_up", "text": "Otimizamos nossas operações em larga escala através da Neologica."},
        {"nome":"React", "img" : "phone_iphone", "text": "Nos esforçamos para criar uma experiência no app que seja clara, didática e ágil ao usuário."},
    ]

    var arrayLivros = [
        {"nome":"Fique Rico Operando Opções", "img" : "imagens/Livro1.jpg", "text": "Guia abrangente sobre como operar opções para construir riqueza. Oferece estratégias práticas e conselhos para investidores de todos os níveis."},
        {"nome":"The Unlucky Investor's Guide to Options Trading", "img" : "imagens/Livro2.jpg", "text": "Manual detalhado que cobre desde matemática básica até estratégias avançadas de venda de opções. Perfeito para investidores que buscam dominar o mercado de opções."},
        {"nome":"O Mais Importante Para o Investidor", "img" : "imagens/Livro3.jpg", "text": "De Howard Marks, oferece uma visão profunda sobre os princípios fundamentais do investimento. O livro destaca a importância da psicologia do mercado, ciclos e a necessidade de uma abordagem disciplinada e fundamentada."},
        {"nome":"O Investidor Inteligente", "img": "imagens/Livro4.jpg", "text":"Escrito por Benjamin Graham, é considerado a bíblia do investimento em valor. Ensina estratégias para investir com segurança e sucesso a longo prazo."},
        {"nome":"Antifrágil", "img": "imagens/Livro5.jpg", "text":"Nassim Nicholas Taleb explora como prosperar em um mundo cheio de incertezas e choques. O livro apresenta conceitos de antifragilidade e como se beneficiar do caos."},
        {"nome":"Iludidos pelo acaso", "img": "imagens/Livro6.jpg", "text":"Também de Nassim Nicholas Taleb, analisa como o acaso influencia nossas vidas e decisões financeiras. Explica a importância de reconhecer o papel da sorte no sucesso."},
        {"nome":"O investidor de bom senso", "img": "imagens/Livro7.jpg", "text":"John C. Bogle defende o investimento em fundos de índice como a maneira mais eficaz de obter retornos consistentes. Oferece conselhos práticos para investidores de longo prazo."},
        {"nome":"The Dao of Capital", "img": "imagens/Livro8.jpg", "text":"Mark Spitznagel integra filosofia austríaca de economia com estratégias de investimento. Explora como uma abordagem indireta pode levar ao sucesso financeiro."},
        {"nome":"As seis lições", "img": "imagens/Livro9.jpg", "text":"Ludwig von Mises apresenta princípios fundamentais da economia de mercado em seis lições. É uma introdução clara e acessível ao pensamento econômico liberal."},
        {"nome":"When Genius Failed", "img": "imagens/Livro10.jpg", "text":"Roger Lowenstein conta a história da ascensão e queda do fundo de hedge Long-Term Capital Management. Revela como a excessiva confiança e complexidade podem levar ao desastre financeiro."}
    ];

    return(
        <div className={`${styles.main}`}>
            <section className={`${styles.secao} ${styles.capaQuemSomos}`} ref={props.quemSomosNos}>
                <div className={`${styles.textoQuemSomos}`}>
                    <div className={`${styles.textoBox}`}>Nós somos a <span className={`${styles.highlightBanner}`}><em>Tech</em>|Um</span></div>
                    <div className={`${styles.textoBox} `}>Especialistas em tecnologia e finanças</div>
                    <div className={` ${styles.textoBox} ${styles.textoDetalheQuemSomos}`}>Ajudamos você a lidar com investimentos complexos de forma simples</div>
                </div>
                <div className={`${styles.imagemQuemSomos}`}>
                    <div className={`${styles.imgctn}`}></div>
                </div>
            </section>
            <section className={`${styles.secaoLoginCadastro}`}>
                <div className={`${styles.loginCadastroBox}`}>
                    <div className="form-text">
                        Já é nosso cliente? Acesse nosso app:
                        <LinkContainer to ="/logIn">
                            <span className="esqueceu-senha-clique"> Entre Aqui!</span>
                        </LinkContainer>
                    </div>
                </div>
            </section>
            <section className={`${styles.secao} ${styles.secaoValores}`}>
                <div className={`${styles.textoValoresBox}`}>
                    <div className={`${styles.textoValoresTitulo}`}>Nossos <span className={`${styles.highlight}`}><em>Valores</em></span></div>
                    <div className={`${styles.textoValores}`}>
                        Desenvolvemos e implementamos <em>estratégias</em> de investimentos especializadas em <em>opções </em>
                        utilizando <em>ferramentas exclusivas</em> e personalizadas.
                        Nossos <em>valores</em> orientam nossas <em>decisões</em> e a construção de <em>relacionamentos com os clientes</em>.
                    </div>
                </div>
                <div className={`${styles.cardCotainerValores}`}>
                    <div className={`${styles.cardValores}`}>
                        <div className={`${styles.cardHeaderValores} ${styles.valoresTransparencia}`}> 
                        </div>
                        <div className={`${styles.cardHeaderTitleValores}`}>
                            Transparência
                        </div>
                        <div className={`${styles.cardBodyValores}`}>
                            Garantimos que nossos clientes tenham acesso claro a todas as informações e decisões. 
                        </div>
                    </div>
                    <div className={`${styles.cardValores}`}>
                        <div className={`${styles.cardHeaderValores} ${styles.valoresResponsabilidade}`}>
                        </div>
                        <div className={`${styles.cardHeaderTitleValores}`}>
                            Responsabilidade
                        </div>
                        <div className={`${styles.cardBodyValores}`}>
                            Avaliamos os riscos em cada decisão, 
                            priorizando a segurança e o crescimento sustentável dos ativos. 
                        </div>
                    </div>
                    <div className={`${styles.cardValores}`}>
                        <div className={`${styles.cardHeaderValores} ${styles.valoresInovacao}`}>
                        </div>
                        <div className={`${styles.cardHeaderTitleValores}`}>
                            Inovação
                        </div>
                        <div className={`${styles.cardBodyValores}`}>
                            Promovemos a inovação em cada estratégia de investimento, 
                            aplicando as mais recentes tecnologias para otimizar nossa operação.
                        </div>
                    </div>
                </div>
            </section>
            <div className={`${styles.backgroundOpcoes}`}>
                <section className={`${styles.secao} ${styles.secaoOpcoes}`}>
                    <div className={`${styles.textoOpcoes}`}>
                        <div className={`${styles.textoBox} ${styles.textoTitulo}`}>O que são <span className={`${styles.highlightDark}`}><em>Opções</em></span> ?</div>
                        <div className={`${styles.textoBox} ${styles.textoDetalhe}`}>
                            <p>Trata-se de uma ferramenta que conecta <em>duas partes</em> com compromisso de <em>venda/compra</em> de um ativo financeiro para uma <em>data futura com preço pré estabelecido.</em></p>
                            <p>Ou seja, alguém terá o <em>direito de vender/comprar</em> um ativo no futuro por um preço pré determinado e a contraparte cumprirá o acordo.</p>
                            <p>Coletamos alguns vídeos de <em>influencers ao redor do Brasil</em> que podem te ajudar a etender o poder dessa ferramenta.</p>
                        </div>
                    </div>
                    <div className={`${styles.carouselBox}`}>
                        <Carousel>
                            <Carousel.Item>
                                <div className={`${styles.carouselBody}`}>
                                    <div className={`${styles.youtubeBox}`}>
                                        <YoutubeEmbed embedId="_e_Knu9SlQE?si=esKpqzdfYhYE3YGN"/>
                                    </div>
                                </div>
                                <Carousel.Caption className={`${styles.carouselCaptionFormat}`}>
                                    <h4></h4>
                                    <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className={`${styles.carouselBody}`}>
                                    <div className={`${styles.youtubeBox}`}>
                                        <YoutubeEmbed embedId="Q51Vu0-0LQE?si=iKhU1c9r5At35qAt"/>
                                    </div>
                                </div>
                                <Carousel.Caption className={`${styles.carouselCaptionFormat}`}>
                                    <h4></h4>
                                    <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className={`${styles.carouselBody}`}>
                                    <div className={`${styles.youtubeBox}`}>
                                        <YoutubeEmbed embedId="LoV8SioLb9o?si=ZwQHncS_r1oGuS_i"/>
                                    </div>
                                </div>
                                <Carousel.Caption className={`${styles.carouselCaptionFormat}`}>
                                    <h4></h4>
                                    <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className={`${styles.carouselBody}`}>
                                    <div className={`${styles.youtubeBox}`}>
                                        <YoutubeEmbed embedId="HOjfD2Xg26M?si=WCZThzE-IXHGOcs9"/>
                                    </div>
                                </div>
                                <Carousel.Caption className={`${styles.carouselCaptionFormat}`}>
                                    <h4></h4>
                                    <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </section>
                <section className={`${styles.secao} ${styles.secaoOpcoesBiblioteca}`}>
                    <div className={`${styles.tituloBiblioteca}`}>
                        <em className={`${styles.highlightDark }`}>Biblioteca</em> Recomendada
                    </div>
                    <div className={`${styles.cardCotainerLivros}`}>
                        {arrayLivros.map((objeto) => (
                            <div className={`${styles.cardLivros}`}>
                                <div className={`${styles.cardImgLivros}`}>
                                    <img src={objeto.img} className={`${styles.imgCardLivro}`} alt="Livro 1" />
                                </div>
                                <div className={`${styles.cardContentLivros}`}>
                                    <div className={`${styles.cardHeaderTitleLivros}`}>
                                        {objeto.nome}
                                    </div>
                                    <div className={`${styles.cardBoddyLivros}`}>
                                        {objeto.text}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
            <div className={`${styles.backgroundTecnologia}`}>
                <section className={`${styles.secao} ${styles.secaoTecnologia}`} ref={props.quemSomosNos}>
                    <div className={`${styles.textoTecnologia}`}>
                        Usando os <em>melhores recursos</em> disponíveis construímos uma plataforma completa para garantir a <em>gestão de risco</em> adequada e <em>transparência</em> aos seus investimentos
                    </div>
                    <div className={`${styles.cardCotainer}`}>
                    {arrayTecnologia.map((objeto) => (
                        <div className={`${styles.card}`}>
                            <div className={`${styles.cardHeaderTitle}`}>
                                {objeto.nome}
                            </div>
                            <div className={`${styles.cardHeader}`}>
                                <span className={` ${styles.textoSymbolsCard} material-symbols-outlined`}>{objeto.img}</span>
                            </div>
                            <div className={`${styles.cardBody}`}>
                                {objeto.text}
                            </div>
                        </div>
                    ))}
                    </div>
                </section>
                <section className={`${styles.secao} ${styles.secaoApp}`} ref={props.quemSomosNos}>
                    <div className={`${styles.imgApp}`}>
                        <img src="imagens/imgApp1.png" className={`${styles.imgApp1}`} alt="Referência ao app" />
                        <img src="imagens/imgApp2.png" className={`${styles.imgApp2}`} alt="Referência ao app" />
                        <img src="imagens/imgApp3.png" className={`${styles.imgApp3}`} alt="Referência ao app" />
                        <img src="imagens/imgApp4.png" className={`${styles.imgApp4}`} alt="Referência ao app" />
                    </div>
                    <div className={`${styles.textoApp}`}>
                        <div className={`${styles.textoBox}`}>Nosso <span className={`${styles.highlight}`}><em>Aplicativo</em></span></div>
                        <div className={` ${styles.textoBoxApp}`}>
                            <div className={` ${styles.symbolBoxApp}`}><span className={` ${styles.textoSymbols} material-symbols-outlined`}>developer_mode</span></div>
                            <div className={` ${styles.textoDetalhe}`}>Foi 100% desenvolvido por nós com foco único de suportar nossa estratégia no mercado de opções</div>
                        </div>
                        <div className={` ${styles.textoBoxApp}`}>
                            <div className={` ${styles.symbolBoxApp}`}><span className={` ${styles.textoSymbols} material-symbols-outlined`}>sync</span></div>
                            <div className={` ${styles.textoDetalhe}`}>Através dele capturamos on-line de forma dinâmica as oportunidades condizentes com nossa metodologia</div>
                        </div>
                        <div className={` ${styles.textoBoxApp}`}> 
                            <div className={` ${styles.symbolBoxApp}`}><span className={` ${styles.textoSymbols} material-symbols-outlined`}>analytics</span></div>
                            <div className={` ${styles.textoDetalhe}`}>Monitoramos os indicadores de riscos e rentabilidade da sua carteira enquanto provemos total transparência para você</div>
                        </div>
                    </div>
                </section>
            </div>
            <section className={`${styles.secaoNossaEquipe}`}>
                <div className={`${styles.textoBoxEquipe}`}>Nossa <span className={`${styles.highlight}`}><em>Equipe</em></span></div>
                <div className={`${styles.membroEquipeCardBox} row`}>
                    <div className={`col-12 col-lg-6 ${styles.membroEquipeCardBox2}`}>
                        <div className={`${styles.membroEquipeCard} `}>
                            <div className={`${styles.membroEquipeCardFoto}`}>
                                <img src="imagens/fillipe.jpeg" className={`${styles.imgCardEquipe}`} alt="Fillipe" />
                            </div>
                            <div className={`${styles.membroEquipeCardTexto}`}>
                                <div className={`${styles.membroEquipeCardTitulo}`}>Fillipe Maciel</div>
                                <div className={`${styles.membroEquipeCardCargo}`}>Diretor de Operações (Co-Founder)</div>
                                <div className={`${styles.membroEquipeCardDescricao}`}>
                                    Fillipe possui mais de 17 anos de experiência no mercado corporativo, com atuação em empresas como BTG, Michelin e Cosan. Especialista em opções, dedica-se há mais de 10 anos ao desenho e aplicação de estratégias variadas.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-lg-6 ${styles.membroEquipeCardBox2}`}>
                        <div className={`${styles.membroEquipeCard} `}>
                            <div className={`${styles.membroEquipeCardFoto}`}>
                                <img src="imagens/diogo.jpeg" className={`${styles.imgCardEquipe}`} alt="Diogo" />
                            </div>
                            <div className={`${styles.membroEquipeCardTexto}`}>
                                <div className={`${styles.membroEquipeCardTitulo}`}>Diogo Lima</div>
                                <div className={`${styles.membroEquipeCardCargo}`}>Diretor Financeiro & RI (Co-Founder)</div>
                                <div className={`${styles.membroEquipeCardDescricao}`}>
                                    Com sólida experiência em Finanças, Diogo atuou na Cosan por 14 anos, passando por áreas como FP&A, M&A e Planejamento Estratégico, além de liderar projetos internacionais nos EUA e na Europa.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-lg-6 ${styles.membroEquipeCardBox2}`}>
                        <div className={`${styles.membroEquipeCard} `}>
                            <div className={`${styles.membroEquipeCardFoto}`}>
                                <img src="imagens/Andrey.jpeg" className={`${styles.imgCardEquipe}`} alt="Andrey" />
                            </div>
                            <div className={`${styles.membroEquipeCardTexto}`}>
                                <div className={`${styles.membroEquipeCardTitulo}`}>Andrey Figueiredo</div>
                                <div className={`${styles.membroEquipeCardCargo}`}>Diretor de Tecnologia (Co-Founder)</div>
                                <div className={`${styles.membroEquipeCardDescricao}`}>
                                    Com 10 anos de experiência em posições estratégicas no Brasil e na Europa, Andrey construiu uma sólida carreira internacional na indústria de Oil & Gas. Tornou-se uma referência por suas habilidades em tecnologia, BI, automação de processos e no desenvolvimento de softwares.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-lg-6 ${styles.membroEquipeCardBox2}`}>
                        <div className={`${styles.membroEquipeCard} `}>
                            <div className={`${styles.membroEquipeCardFoto}`}>
                                <img src="imagens/Andre.jpeg" className={`${styles.imgCardEquipe}`} alt="André" />
                            </div>
                            <div className={`${styles.membroEquipeCardTexto}`}>
                                <div className={`${styles.membroEquipeCardTitulo}`}>André Cardozo</div>
                                <div className={`${styles.membroEquipeCardCargo}`}>Head de Operações</div>
                                <div className={`${styles.membroEquipeCardDescricao}`}>
                                    Com 17 anos de experiência no mercado financeiro, André atuou em grandes empresas como BBDTVM/Banco do Brasil, Brasilcap e BNY Mellon, em posições voltadas a investimentos e risco. Em 2024, tornou-se Head de Operações na TechUm.
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-lg-6 ${styles.membroEquipeCardBox2}`}>
                        <div className={`${styles.membroEquipeCard} `}>
                            <div className={`${styles.membroEquipeCardFoto}`}>
                                <img src="imagens/Renata.jpeg" className={`${styles.imgCardEquipe}`} alt="Renata" />
                            </div>
                            <div className={`${styles.membroEquipeCardTexto}`}>
                                <div className={`${styles.membroEquipeCardTitulo}`}>Renata Farias</div>
                                <div className={`${styles.membroEquipeCardCargo}`}>Head de RI</div>
                                <div className={`${styles.membroEquipeCardDescricao}`}>
                                    Desde 2008, Renata atuou em posições comerciais e estratégicas em empresas como B2W, Grupo Bravante e Grupo CBO, com foco em investimentos e proteção patrimonial. Em 2024, ingressou na TechUm como Head Comercial para apoiar a expansão do negócio.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`${styles.secaoContato}`}>
                <div className={` ${styles.contatoTitulo}`}>Entre em contato</div>
                <div className={` ${styles.contatoBox}`}>
                    <div><span className={` ${styles.contatoSymbol} material-symbols-outlined`}>Call</span></div>
                    <div className={` ${styles.contatoCategoria}`}>Telefone:</div>
                    <div className={` ${styles.contatoNumero}`}>(21) 98880 - 4293</div>
                </div>
                <div className={` ${styles.contatoBox}`}>
                    <div><span className={` ${styles.contatoSymbol} material-symbols-outlined`}>Chat</span></div>
                    <div className={` ${styles.contatoCategoria}`}>Whatsapp:</div>
                    <div className={` ${styles.contatoNumero}`}>(21) 98880 - 4293</div>
                </div>
                <div className={` ${styles.contatoBox}`}>
                    <div><span className={` ${styles.contatoSymbol} material-symbols-outlined`}>Mail</span></div>
                    <div className={` ${styles.contatoCategoria}`}>E-mail:</div>
                    <div className={` ${styles.contatoNumero}`}>contato@techum.com</div>
                </div>
            </section>
        </div>
    )

}

export default WebSite